import React from 'react';
import Form from '../../common/form';
import Joi from 'joi-browser';
import userService from '../../../services/userService';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { schools } from '../../../schools.json';


class EditUser extends Form {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                id: '',
                username: '',
                fullname: '',
                email: '',
                school: '',
                postNumber: '',
                parent_email: '',
                showParent: false,
                is_ai_school: true,
                is_disabled: false,
                is_sonstige:false
            },
            errors: [],
            showPanel: true,
            success: '',
            modal: null,
            showOlderWarning: '',
            showYoungerWarning: ''
        };
        this.handleIsAISchool = this.handleIsAISchool.bind(this);
    }

    showPanel(modalItem) {
        this.setState({
            showPanel: true,
            modal: {
                title: modalItem.title,
                content: modalItem.content
            }
        });
    }

    closeDefinition = () => {
        this.setState({
            showPanel: false,
            modal: null
        })
    }

    getDate = (years) => {
        return moment().subtract(years, 'years')._d
    }

    schema = {
        id: Joi.string().allow().optional(),
        showParent: Joi.boolean().optional(),
        username: Joi.string().allow('').optional(),
        email: Joi.string().email().allow('').optional(),
        is_ai_school: Joi.boolean().optional(),
        school: Joi.when('is_ai_school', {
            is: false,
            then: Joi.string().allow('').optional(),
            otherwise: Joi.string().allow('').required()
        }),
        fullname: Joi.when('is_ai_school', {
            is: false,
            then: Joi.string().allow('').optional(),
            otherwise: Joi.string().allow('').required()
        }),

        postNumber: Joi.when('is_ai_school', {
            is: false,
            then: Joi.number().integer().allow('').optional(),
            otherwise: Joi.number().integer().allow('').required()
        }),

        parent_email: Joi.string().allow('').optional(),
        is_disabled: Joi.boolean().optional(),
        is_sonstige: Joi.boolean().optional()

    }


    componentWillReceiveProps(nextProps) {
        let birth = '';
        if (nextProps.response._id) {
            if (nextProps.response.birthday === undefined) {
                birth = '';
            }
            else {
                let birthday = moment(nextProps.response.birthday.$date);
                birth = birthday.format("YYYY-MM-DD");
            }

            this.setState({
                data: {
                    id: nextProps.response._id.$oid,
                    username: nextProps.response.username,
                    fullname: nextProps.response.fullname,
                    email: nextProps.response.email,
                    school: nextProps.response.school,
                    postNumber: nextProps.response.post_number,
                    parent_email: nextProps.response.parent_email,
                    is_ai_school: nextProps.response.is_ai_school,
                    is_disabled: nextProps.response.is_ai_school,
                    is_sonstige: nextProps.response.school === 'Sonstige' ? true: false
                },
            })
        }
    }

    handleChangeDate = (date) => {
        const { data } = this.state;

        data.formData = date ? moment(date).format("YYYY-MM-DD") : null
        this.setState({
            date
        })
    };

    handleIsAISchool(event) {
        const { data } = this.state;
        data['is_ai_school'] = event.target.checked;

        this.setState({
            data
        })

    }

    handleBirthdayChange = ({ currentTarget: input }) => {
        const { data } = this.state;

        setTimeout(() => {
            const errors = { ...this.state.errors };
            data.formData = input.value ? moment(input.value).format("YYYY-MM-DD") : null;

            let showOlderWarning = false;
            if (moment(input.value) < moment(this.getDate(20))) {
                //this.props.onShowOlderWarning(true);
                showOlderWarning = true;
                data.showParent = false;
            }
            else {
                //this.props.onShowOlderWarning(false);
                showOlderWarning = false;
            }

            let showYoungerWarning = false;
            if (moment(input.value) > moment(this.getDate(13))) {
                //this.props.onShowYoungerWarning(true);
                showYoungerWarning = true;
                data.showParent = true;
            }
            else {
                //this.props.onShowYoungerWarning(false);
                showYoungerWarning = false;
            }

            this.setState({
                data,
                errors,
                showOlderWarning,
                showYoungerWarning
            })
        }, 600);

    }


    handleChangeRadio = (event) => {
        const { data } = this.state;
        data['gender'] = event.target.value;
        this.setState({
            data
        })
    }


    handleInputChange = (event) => {
        const { data } = this.state;
        let school = event.target.value;
        data['school'] = school.replace(' ','-');
        this.setState({
            data
        })
    }

    submitForm = () => {
        const { data } = this.state;
        const { id, fullname, formData, postNumber, parent_email, school, is_ai_school } = data;

        userService.editUser(id, fullname,
            postNumber, school, is_ai_school).then(({ data:response }) => {
                this.setState({
                    success: 'true',
                    data: {
                        id: response._id.$oid,
                        username: response.username,
                        fullname: response.fullname,
                        email: response.email,
                        school: response.school,
                        postNumber: response.post_number,
                        parent_email: response.parent_email,
                        is_ai_school: response.is_ai_school,
                        is_disabled: response.is_ai_school,
                        is_sonstige: response.school === 'Sonstige' ? true: false,
                    }
                })
            }).catch(err => {
                this.setState({
                    success: 'false'
                })
            })
    }

    render() {
        let options = [];
        schools.forEach(school => {
            if(school === 'Sonstige'){return;}
            options.push(<option>{school}</option>)
        })
        const modal_content = 'Die Schule mit den meisten aktiv teilnehmenden Schüler*innen am KI-Kurs, während der Wettbewerbszeit (März-Oktober), hat die Chance den Titel der KI-Schule des Jahres zu gewinnen. Du willst mitmachen? Markiere bei der Registrierung oder im Anschluss in deinem Benutzerprofil, dass du teilnehmen möchtest und informiere uns an welcher Schule du Schüler*in bist.';
        const modal_title = 'KI Schule des Jahres';
        const modalItem = { 'title': modal_title, 'content': modal_content }
        const { data, errors, success, showOlderWarning, showYoungerWarning, showPanel, modal } = this.state;
        const { username, fullname, email, school, postNumber,
            parent_email, is_ai_school, is_disabled, is_sonstige } = data;
        return (
            <div className={`col-md-12`}>
                <div className='card'>
                    <div className='card-body'>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="text-center">Benutzerprofil</h2>
                                <hr />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="Benutzername">Benutzername</label>
                                        <input className="form-control" name='username' value={username} type="text" placeholder={""} disabled />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="Email">Email</label>
                                        <input className="form-control" name='email' data-teamindex="0" value={email} type="email" disabled />
                                    </div>
                                </div>
                                <div className='form-row'>
                                    <div className={`form-group col-md-6`}>
                                        <label htmlFor="ParentEmail">E-Mail des Erziehungsberechtigten</label>
                                        <input className={`form-control`} name='parent_email' data-teamindex="0" value={parent_email} type="email" disabled />
                                    </div>
                                </div>
                                <hr />
                                <div className='form-row'>
                                    <div className="form-group">
                                        <div className='checkbox-wrapper'>
                                            <div className="chechbox-terms">
                                                <label className="switch">
                                                    {!is_disabled ?
                                                        <input type="checkbox" id="is_ai_school" name="is_ai_school" checked={is_ai_school} onChange={this.handleIsAISchool} />
                                                        :
                                                        <input type="checkbox" id="is_ai_school" name="is_ai_school" checked={is_ai_school} disabled />
                                                    }
                                                    <span className="slider round"></span>
                                                </label>
                                                <span className={is_disabled ? 'grayout' : ''}>
                                                    Wenn du am Rennen um den Titel der<a href='#' onClick={this.showPanel.bind(this, modalItem)}> KI-Schule des Jahres </a>teilnehmen möchtest, klicke hier  und fülle die folgenden Felder aus.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="Vollständiger Name ">Vollständiger Name </label>
                                        <input className='form-control' name='fullname' value={fullname} onChange={this.handleChange} type="text" placeholder="" />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label className={is_disabled ? 'grayout' : ''} htmlFor="PLZ">PLZ</label>
                                        <input name='postNumber' value={postNumber} onChange={this.handleChange} type="text" placeholder="PLZ" className={`form-control ${errors.postNumber ? 'invalid' : ''}`} disabled={is_disabled} />
                                    </div>
                                    {is_sonstige ?
                                        <div className="form-group col-md-4">
                                            <label className={is_disabled ? 'grayout' : ''} htmlFor="Schule">Schule</label>
                                            <div>
                                                <input name='school' value={school} onChange={this.handleInputChange} type="text" placeholder={"Schule"} className={errors.school ? 'invalid form-control' : "form-control"} />
                                            </div>
                                        </div>
                                        :
                                        <div className="form-group col-md-4">
                                            <label className={is_disabled ? 'grayout' : ''} htmlFor="Schule">Schule</label>
                                            <div>
                                                <input list='schools' name='school' value={school} onChange={this.handleChange} type="text" placeholder={"Schule"} className={errors.school ? 'invalid form-control' : "form-control"} />
                                                <datalist id='schools'>
                                                    {options}
                                                </datalist>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={`button-div`}>
                                <button className="btn btn-primary" type='submit' onClick={this.handleSubmit}>Speichern</button>
                                
                            </div>
                            <div className="message-wrapper">
                                {success === 'true' &&
                                    <div className='alert alert-success'>Deine Veränderungen wurden erfolgreich gespeichert!</div>
                                 } 
                                {success === 'false' &&
                                    <span className='alert alert-danger'>Deine Veränderungen konnten nicht gespeichert werden.</span>
                                }
                            </div>

                            {
                                showPanel && modal &&
                                <div className="overlay-panel-container">
                                    <div className="overlay-panel">
                                        <div className="definition-header">{modal.title}</div>
                                        <div className="section">
                                            <p className='text-justify'>{modal.content}</p>
                                        </div>
                                        <div className="close" onClick={this.closeDefinition}></div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditUser;