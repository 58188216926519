import React, { Component } from 'react';
import { allQuestions } from '../../questions.json';
import assignmentsService from '../../services/assignmentsService';
import questionService from '../../services/questionService';
import userService from '../../services/userService'
import authService from '../../services/authService'
import { element } from 'prop-types';
import ViewModeAlert from '../viewModeAlert'
import Loading from '../common/loading'


class CodeSubmissionBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMode:true,
            showViewModeMessage:false,
            assignments: [],
            course:this.props.match.params.course,
            index: {
                index1: 0,
                index2: 0,
                index3: 0,
                index4: 0,
                index5: 0,
            },
            response: [],
            chapterNames:[
                            {"dog":[
                                    "Einführung",
                                    "Grundlagen der Programmierung",
                                    "Klassifikator",
                                    "Gradientenverfahren",
                                    "Neuronale Netze"
                                ]
                            },
                            {"recycling":[
                                "Daten, Daten, Daten",
                                "Theoretische Grundlagen",
                                "Implementierung: Basics",
                                "Implementierung: Optimierung",
                                "Implementierung: Projektskript",
                                "Zusatz: Umsetzung auf Raspberry Pi"
                            ]
                        }
                ],
            courseMetaData:null,
            showLoading:true

        }
    }

    componentDidMount() {

        let user = authService.getCurrentUser();

        /*TODO: this is the piecec of code to get subchapters from metadata
        * remove this once the chapters/subchapters are finalized
        */
        // assignmentsService.getCourseMetadta('python').then(({data:response}) =>{
        //     let courseMetaData = response

        //     let chapters = ""
        //     courseMetaData && courseMetaData.map((chapter,index) => {
        //         chapters = chapters + "'" + chapter.chapter + "',"
        //     })
        
        //     console.log(chapters)

        // }).catch(err => {
        //     this.setState({viewMode:true,showLoading:false})
        // })
        // End of remove

        if(!user){

            assignmentsService.getCourseMetadta(this.state.course).then(({data:response}) =>{
                this.setState({
                    courseMetaData:response,
                    viewMode:true,
                    showLoading:false})
            }).catch(err => {
                this.setState({viewMode:true,showLoading:false})
            })
        }
        else{
            assignmentsService.getAssignments(this.state.course).then(({ data: response }) => {
                userService.getScoresForCourse(this.state.course).then(({data:responsePoints}) => {
    
                    let points = responsePoints;
                    let allchapters = response.assignments.map( p => p.id.charAt(0)).filter(item => !isNaN(item))
    
                    allchapters = [...new Set(allchapters)]
                    let chapters=[]
    
                    let allAssignments = response.assignments;
                    allAssignments = allAssignments.map(assingment => { 
                        let scoreObj = responsePoints.find( pointsItem => pointsItem.chapter_id === assingment.id)
                        assingment.score = scoreObj? scoreObj.points:0;
                        return assingment })
        
                    allchapters.forEach(chapter => {
                        chapters[chapter] = response.assignments.filter(p => p.id.startsWith(chapter))
                    });
    
    
                    this.setState({
                        assignments: [].concat(response.assignments),
                        chapters: chapters,
                        viewMode:false,
                        showLoading:false
                    })
                    
                })
            }).catch(err => { })
            questionService.getMultipleQuestionsByType(this.state.course).then(({ data: response }) => {
                this.setState({
                    response
                })
            })
        }       
    }

    onChapterClick = (element) => {

        let root = '/programming/tutorials'        
        if (element[0].video && element[0].video === true) {
            this.props.history.push({
                pathname: `${root}/chapter-video/course=${this.state.course}/id=${element[0].id}`,
            })
        }
        else if (element[0].questions && element[0].questions !== null) {
            let allQuestions = JSON.parse(element[0].questions);
            this.props.history.push({
                pathname: `${root}/code-questions/course=${this.state.course}/id=${element[0].id}/questionId=${allQuestions.allQuestions[0].id}`
            })
        }
        else if (element[0].description && element[0].description !== null) {
            this.props.history.push({
                pathname: `${root}/code-submission/course=${this.state.course}/id=${element[0].id}`
            })
        }
    }

    handleVideoInChapter(element) {

        let ids = element.id.split("_");
        let chapterId = Number(ids[0]);
        let subChapterId = Number(ids[1]);
        let currentChapter = this.state.chapters[Number(chapterId)];
        let videoItem = currentChapter.filter((item) => {
            let ids = item.id.split('_');
            if (ids.includes('v') && Number(ids[1]) === subChapterId-1){
                return true;
            }   
            else
                return false;
        });
        
        if(videoItem && videoItem[0])
            return videoItem[0];
        else
            return element
    }

    onQuestionClick = (event, item) => {
        let root = '/programming/tutorials'  

        let element = this.handleVideoInChapter(item)
        if (element.video && element.video === true) {
            this.props.history.push({
                pathname: `${root}/chapter-video/course=${this.state.course}/id=${element.id}`,
            })
        }
        else if(element.intro && element.intro !== null){
            this.props.history.push({
                pathname: `${root}/intro/course=${this.state.course}/id=${element.id}`
            })

        }
        else if (element.questions && element.questions !== null) {
            // let allQuestions = JSON.parse(element.questions);
            this.props.history.push({
                pathname: `${root}/code-questions/course=${this.state.course}/id=${element.id}/questionId=1`
            })
        }
        else if (element.description && element.description !== null) {
            this.props.history.push({
                pathname: `${root}/code-submission/course=${this.state.course}/id=${element.id}`
            })
        }

        if(!event)
            var event = window.event;
            event.cancelBubble = true;
        if (event.stopPropagation) 
            event.stopPropagation();
    }

    getIndex = (indexChapter) => {
        let { index } = this.state
        index[indexChapter] = index[indexChapter] + 1;
        return index[indexChapter];
    }

    getScore = (element, response) => {
        let total = 0;
        let n_correct = 0;
        JSON.parse(element.questions).allQuestions.map((element1, index1) => {
             if (response.find(p => p.quiz_id === element1.id && p.chapter_id === element.id)) {
                n_correct += response.find(p => p.quiz_id === element1.id && p.chapter_id === element.id).result.n_correct;
                total += response.find(p => p.quiz_id === element1.id && p.chapter_id === element.id).result.total;
             }
        })
        return n_correct + "/" + total;
    }

    toggleViewModeMessage = () => {
        this.setState({
            showViewModeMessage:!this.state.showViewModeMessage
        })
    }

    onRegister = () => {
        this.props.history.push({
          pathname: `/auth`
        })
        this.setState({
            showViewModeMessage:false
        })
      }
    

    render() {
        const {  response, chapters, chapterNames,course, viewMode, courseMetaData, showViewModeMessage, showLoading } = this.state;
        return (
            <React.Fragment>
                <div className="content tutorial-landing">
                    {!showLoading && <div className={`grid-box multiple-choice ${course === 'recycling'?'narrow-tile-container':''}`}>
                        {(!viewMode) ?
                            (chapters && chapters.map((chapter,index) => {
                                if(chapter)
                                    return(
                                    <div className={`${course === 'recycling'?'':'dog-job-box'} job-box margin-top-10`} onClick={this.onChapterClick.bind(this, chapter)} >
                                        <div className={`image-box`}  >
                                            <img src={`/images/icons/subpaths/programming/tutorials/${course}/${course}_${index}.svg`} alt="" />
                                        </div>
                                        <div className={`content-box`}>
                                            <span className={`chapter`} >{`KAPITEL ${index}`}</span>
                                            <span className={`title primary-color-lightblue-foreground`} >{ course==="dog"?chapterNames[0].dog[index-1]:chapterNames[1].recycling[index-1]}</span>
                                            <div className={`section`}>
                                                <span>Abschnitt</span>
                                                <span>Punkte</span>
                                            </div>
                                            <div className={`question-section`}>
                                                { 
                                                    chapter.map((element, index1) =>
                                                        (element.questions || element.intro || element.description) &&
                                                        <React.Fragment>
                                                            <div key={index1} className={`question`} onClick={ (e) => {this.onQuestionClick(e, element)}}>
                                                                {/* <div className={`line`}></div> */}
                                                                <span style={{paddingRight:'10px'}}>{element.title} </span>
                                                                <span>{element.score}</span>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </div>
                                        </div>
                                </div>)
                            })):
                                (courseMetaData && courseMetaData.map((chapter,index) => {

                                    if(chapter)
                                        return(
                                        <div className={`${course === 'recycling'?'':'dog-job-box'} job-box margin-top-10`} onClick={this.toggleViewModeMessage} >
                                            <div className={`image-box`}  >
                                                <img src={`/images/icons/subpaths/programming/tutorials/${course}/${course}_${index+1}.svg`} alt="" />
                                            </div>
                                            <div className={`content-box`}>
                                                <span className={`chapter`} >{`KAPITEL ${index+1}`}</span>
                                                <span className={`title primary-color-lightblue-foreground`} >{ chapter.chapter}</span>
                                                <div className={`section`} >
                                                    <span>Abschnitt</span>
                                                </div>
                                                <div className={`question-section`}>
                                                    { 
                                                        chapter.sub_chapters && chapter.sub_chapters.map((element, index1) =>
                                                            <React.Fragment>
                                                                <div key={index1} className={`question`} >
                                                                    {/* <div className={`line`}></div> */}
                                                                    <span style={{paddingRight:'10px'}}>{element} </span>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                    </div>)

                                } 
                            ))
                        }
                    </div>}
                    {showViewModeMessage && <ViewModeAlert onRegister={this.onRegister} onCancel={this.toggleViewModeMessage} />}
                    {showLoading && <Loading />}
                </div>
                
            </React.Fragment>
        )
    }
}

export default CodeSubmissionBox;