import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import MyTooltip from './my-tooltip'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class TextEditor extends Component {
    state = {
        editorState: EditorState.createEmpty(),
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        });

        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const { onChange, name } = this.props;
        onChange({ currentTarget: { name: name, value: html, type: 'editor' } });
    };

    render() {
        const { editorState } = this.state;
        const { name, label, error = false, horizontal = true, tooltip = false, message = "" } = this.props;
        var divClass = '';
        var parentDiv = "form-group mt-4";
        var labelClasses = '';
        if (horizontal) {
            divClass = 'col-sm-9';
            labelClasses += ' col-sm-3 col-form-label'
            parentDiv += ' row';
        }

        return (
            <div className={parentDiv}>
                <label className={labelClasses}>
                    {label}
                    {tooltip && <MyTooltip name={name} message={message} />
                    }
                </label>
                <div className={divClass}>
                    <Editor
                        editorState={editorState}
                        wrapperClassName={`${error ? 'invalid-editor' : ''}`}
                        onEditorStateChange={this.onEditorStateChange}
                    />
                </div>
            </div>);
    }
}

export default TextEditor;