import React, { Component } from 'react';
import authService from '../services/authService';
import { Link } from 'react-router-dom';
import * as alert from '../alert.json';
import userService from '../services/userService'

class tutorials extends Component {
    state = {
        tutorials:[{
            course:'python',
            title:"Grundlagen der Python Programmierung",
            description:"",
            subHeader:"Python Grundkurs",
            link:"/programming/pythonTutorials/course=python",
            image:"python.svg",
            totalTasks:80,
            attemptedTasks:0
        },
        {
            course:'ML',
            title:"Grundlagen des Maschinellen Lernens ",
            description:"",
            subHeader:"ML Grundkurs",
            link:"/programming/pythonTutorials",
            image:"comingsoon.svg",
            totalTasks:80,
            attemptedTasks:0
        }],
        viewMode:true,
        taskPythonBasics:0
       
    }

    componentDidMount() {

        let user = authService.getCurrentUser();

        if(!user){
            this.setState({
                viewMode:true
            })
        }
        else{

        //    let tasksDog = 0;
        //    let tasksRecycling = 0;
        //     this.state.tutorials.map(tutorial => {
        //         userService.getAttemptedTasksForCourse(tutorial.course).then(({ data: response })=>{

        //             if(!response){
        //                 return
        //             }

        //             let sum = 0;
        //             if(tutorial.course === 'dog'){
        //                 response.map((subchapter) => {
        //                     sum+= subchapter.num_attempted;
        //                 })
        //                 this.setState({taskDog:sum})
        //             }
        //             else if(tutorial.course === 'recycling'){
        //                 response.map((subchapter) => {
        //                     sum+= subchapter.num_attempted;
        //                 })
        //                 this.setState({taskRecycling:sum})
        //             }

        //         })
        //     })
        }

    }

    removeAlert = () => {
        this.setState({
            showAlert: false
        })
    }

    render() {

        const {taskPythonBasics,taskRecycling} = this.state;
        const user = authService.getCurrentUser();
        let viewMode=true;
        if(user)viewMode=false;
        return (
            <React.Fragment>
                <section className="main-content">

                        <div className="tiles-container tutorials-tiles">
                        {this.state.tutorials.length > 0 && this.state.tutorials.map( (tutorial) => (
                             <Link to={tutorial.link} className="subpath-tile tutorial">
                                <div className="tile-wrapper">
                                    <img style={{width:'100%'}} src={`/images/icons/subpaths/programming/pythonTutorials/${tutorial.image}`}/>
                                    <div className="row2 primary-color-lightblue-background">
                                        <div className="tile-sub-header">{tutorial.subHeader}</div>
                                        <div className="tile-header fix-height-3">{tutorial.title}</div>
                                        {/* { !viewMode && <div className="tile-status">{`${tutorial.course === 'dog'?taskDog:taskRecycling}/${tutorial.totalTasks} Aufgaben bearbeitet`}</div>} */}

                                    </div>
                                </div>
                             </Link>))
                        }
                          
                        </div>
                    
                </section>
            </React.Fragment>
        );
    }
}

export default tutorials;
