import React from 'react';
import { tips } from '../../tips_and_tricks.json';

const TipsAndTricks = () => {
    return (
        <React.Fragment>
            <section className="content">
                <div className="code-page-limit-tipps ">
                    <div className="tittle">
                        {tips &&
                            tips.map((tip, index) =>
                                <div>
                                    <span className="tips-question">{tip.id} {tip.question}</span>
                                    <br/>
                                    <span className="tips-answer">{tip.answer}</span>
                                    <br/>
                                    {tip.link && <span className="link"><a href={tip.link} target="_blank"> {tip.linkHeader? tip.linkHeader : "Klick hier für den Artikel!"}</a></span>}
                                    <br/>
                                    <br/>
                                </div>
                        )}
                    </div>
                </div>
             </section>
        </React.Fragment>
    );
}
export default TipsAndTricks;
