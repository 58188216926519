import * as types from '../constants/can-submit'
import * as moment from 'moment'

export const canSubmit = () => {
    return dispatch => {
        const deadline = moment(process.env.REACT_APP_DEADLINE, "DD.MM.YYYY HH:mm:ss");
        const can = moment(deadline).diff(moment.now(), 'seconds') > 0
        dispatch({
            type: types.CAN_SUBMIT,
            canSubmit: can
        })
    }
}