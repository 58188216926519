import React,{useEffect,useState}from 'react';
import { tasks } from "../../tasks.json";
import easy from "../../img/difficulty_1.svg";
import medium from "../../img/difficulty_2.svg";
import hard from "../../img/difficulty_3.svg";
import schueler from "../../img/Schueler_Task.svg";

const TaskOfTheWeek = (props) => {

    const [taskData, setTaskData] = useState({})
    useEffect(() => {
        const {params} = props.match
        const task = tasks.filter(task => task.title === params.id)[0]
        setTaskData(task)
        return () => {
          
        }
      }, [''])

    return (
        <React.Fragment>
            <div className="content-base">
                <div className="task-main">
                        <div className="task-content"  >
                            <div className="task-header primary-color-lightblue-background" >
                                {taskData.studenttask && <img
                                                        className="tasks-schueler"
                                                        alt="Schüleraufgabe"
                                                        src={schueler}
                                                        /> }
                                <div className="task-title">{taskData.title} </div>
                                <p className ="task-category">{taskData.category} </p>
                            </div>
                            <p ClassName="task-description">{taskData.description} </p>
                            <p><span style={{fontWeight:'bold'}}>Lernziele : </span>{taskData.objective} </p>

                            <div className="task-footer">
                                <a className="task-action-btn" href={taskData.notebook} target="_blank">Aufgabe</a>
                                <a className="task-action-btn" href={taskData.solution} target="_blank">Lösung</a>

                                {taskData.difficulty === 1 && <img className="task-diff-img"  alt="Leicht" src={easy} /> }
                                {taskData.difficulty === 2 && <img className="task-diff-img" alt="Mittel" src={medium} />}
                                {taskData.difficulty === 3 && <img className="task-diff-img"  alt="Schwierig" src={hard} />}
                            </div>
                        </div>
                    {/* <div className="previous-link" onClick={()=>{props.history.push('/task-archive')}} >&lt;&lt; Aufgabenpool</div> */}
                </div>
                
            </div>
            
        </React.Fragment>
    );
}
export default TaskOfTheWeek;