import { checkPropTypes } from 'prop-types';
import React, { Component } from 'react';

class viewModeAlert extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="overlay-panel-container">
           <div className="overlay-panel view-mode-message">
             <div className="panel-content">
              <div className="message-title">Um weiterzumachen ist eine Registrierung notwendig.</div>
              <div className="button-wrapper">
                <button style={{color:"#fff", backgroundColor:"#0083d3"}}onClick={this.props.onRegister}>Registrieren</button>
                <button onClick={this.props.onCancel}>Ablehnen</button>
              </div>
            </div>
           </div>
        </div>
        
      </React.Fragment>
    );
  }
}


export default viewModeAlert;
