import React, { Component } from 'react';

function Intro() {
    return (
        <section id='worum-gehts' className="womix__intro-text" data-scroll="intro">
            <div className="inner-wrap">
                <div className="womix__video-wrapper">
                    <div> 
                    <iframe src="https://www.youtube.com/embed/YbShdrk8IQg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="text-wrapper">
                    <p class="intro-desc"> 
                        <strong>Du</strong> möchtest die <strong>Zukunft mitgestalten</strong> und verstehen, 
                        wie intelligente Algorithmen funktionieren? Du siehst in Künstlicher Intelligenz 
                        die Chance, Probleme zu lösen und die Welt zu verbessern? Dann melde dich 
                        <a href="https://ki-kurs.asai.ac.at/app/auth" target="_blank">hier</a> an, um die Grundlagen der KI zu erlernen!
                        <br/>
                        <br/>
                        Dieser Kurs ist <strong>der ideale Einstieg für Interessierte</strong>, die verstehen und lernen wollen, 
                        wie KI funktioniert. Wir führen dich über die Grundlagen der Programmierung über einfache 
                        Klassifikatoren bis hin zu neuronalen Netzen. Du wirst sehen: KI ist gar nicht so schwer!
                        <br/>
                        KI bringt auch Verantwortung mit sich. Im Kurs setzt du dich ebenso mit ethischen und gesellschaftlichen
                         Auswirkungen von KI auseinander. Wir haben die <strong>brennendsten Themen</strong> für dich zusammengestellt. 
                         Werde KI-Experte!
                        <br/>

                    </p>
                </div>
            </div>
            <div className="womix__image-wrapper"> <img src="static/img/NeuroNetz_nur_ecke.png" alt="" className="" /> </div>
        </section>
    )
}

export default Intro;