import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AppProvider from './components/common/appProvider'
import Logout from './components/auth/logout';
import SubmitQuestions from './components/questions/submit-questions'
import CodeSubmission from './components/code-submission/code-submission';
import CodeQuestions from './components/code-submission/code-questions';
import CodeSubmissionBox from './components/code-submission/code-submission-box';
import VideoPage from './components/code-submission/video-page';
import NotFound from './components/not-found';
import Leaderboard from './components/leaderboard/leaderboard';
import MyStatus from './components/status/my-status';
import ProtectedRoute from './components/common/protected-route';
import authService from './services/authService';
import NavBar from './components/nav-bar';
import ForgotPassword from './components/auth/forgot-password';
import ChangePassword from './components/auth/change-password';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Footer from './components/footer';
import Auth from './components/auth/auth';
import ConfirmUser from './components/auth/confirm-user';
import EntryPage from './components/entry_page';
import UserProfile from './components/profile/user_profile'
import Cookies from './components/cookies';
import TaskOfTheWeek from './components/task-of-the-week/task-of-the-week'
import TaskArchive from './components/task-archive/task-archive'
import Task from './components/task-archive/task'
import TipsAndTricks from './components/tips_and_tricks/tips_and_tricks'
import PythonInstallation from './components/python-installation/python-installation'
import MultipleChoiceGrid from './components/questions/multiple-choice'
import Certificate from './components/certificate/certificate';


import AppBanner from './components/AppBanner/appBanner'
import Homepage from './pages/homepage'
import Milestones from './pages/milestones'
import Programming from './pages/programming'
import Ethics from './pages/ethics'
import Tutorials from './pages/tutorials';
import AiSchool from './pages/aiSchool';
import definition from './pages/definition';
import memory from './pages/memory';
import concepts from './pages/concepts';
import IntroPage from './components/code-submission/intro-page';
import PythonTutorials from './pages/pythonTutorials'
import CourseMain from './components/course/CourseMain'
import CourseContent from './components/course/CourseContent';
import ConclusionPage from './components/code-submission/conclusion-page';


class App extends Component {
  render() {
    const user = authService.getCurrentUser();
    return (
      <AppProvider>
      <Route render={({ location }) => (
        <React.Fragment>
          {location.pathname !== '/auth' && location.pathname !== '/' && location.pathname !== '/change-password' && location.pathname !== '/confirm-user' && location.pathname !== '/datenschutz' && <AppBanner role={location.pathname === '/'} path ={location.pathname} />}
          <TransitionGroup>
            <CSSTransition key={location.key} timeout={300} classNames="fade">
              <React.Fragment>
                <Switch>
                  <Route path="/auth" component={Auth} />
                  {/* <Route path="/entry-page" component={EntryPage} /> */}
                  <Route path='/logout' component={Logout} />
                  <Route path='/forgot-password' component={ForgotPassword} />
                  <Route path='/change-password' component={ChangePassword} />
                  <Route path='/confirm-user' component={ConfirmUser} />
                  <Route path="/milestones/definition" component={definition} />
                  <Route path="/milestones/memory" component={memory} />
                  <Route path="/milestones/concepts" component={concepts} />
                  <Route path="/milestones" component={Milestones} />
                 
                  <ProtectedRoute path="/ethics/course=:course/chapter=:chapterId/id=:id" component={SubmitQuestions} />
                  <Route path="/ethics/course=:course" component={MultipleChoiceGrid} />
                  <Route path="/ethics" component={Ethics} viewMode={true} />

                  <ProtectedRoute path="/programming/pythonTutorials/code-questions/course=:course/id=:id/questionId=:questionId" component={CodeQuestions} />
                  <ProtectedRoute path='/programming/pythonTutorials/code-submission/course=:course/id=:id' component={CodeSubmission} />   
                  <ProtectedRoute path='/programming/pythonTutorials/chapter-video/course=:course/id=:id' component={VideoPage} />
                  <ProtectedRoute path='/programming/pythonTutorials/intro/course=:course/id=:id' component={IntroPage} />
                  <ProtectedRoute path="/programming/pythonTutorials/course-content/course=:course/id=:id" component={CourseContent} />
                  <ProtectedRoute path='/programming/pythonTutorials/conclusion-page/course=:course/id=:id' component={ConclusionPage} />
                  <Route path="/programming/pythonTutorials/course=:course" component={CourseMain} />
                  <Route path="/programming/pythonTutorials" component={PythonTutorials} />
                  

                  <ProtectedRoute path="/programming/tutorials/code-questions/course=:course/id=:id/questionId=:questionId" component={CodeQuestions} />
                  <ProtectedRoute path='/programming/tutorials/code-submission/course=:course/id=:id' component={CodeSubmission} />   
                  <ProtectedRoute path='/programming/tutorials/chapter-video/course=:course/id=:id' component={VideoPage} />
                  <ProtectedRoute path='/programming/tutorials/intro/course=:course/id=:id' component={IntroPage} />
                  <ProtectedRoute path='/programming/tutorials/conclusion-page/course=:course/id=:id' component={ConclusionPage} />
                  <Route path='/programming/tutorials/course=:course' component={CodeSubmissionBox} />    
                  
                  <Route path="/programming/tutorials" component={Tutorials} />
                  <Route path='/programming/task-archive/task/:id' component={Task} />
                  <Route path='/programming/task-archive' component={TaskArchive} />  
                  <Route path="/programming" component={Programming} viewMode={true} />
                  <Route path="/school-of-the-year" component={AiSchool} />
                  <ProtectedRoute user={user} path='/leaderboard' component={Leaderboard} />
                  <ProtectedRoute path='/my-status' component={MyStatus} />
                  <ProtectedRoute user={user} path='/user-profile' component={UserProfile} />
                  <ProtectedRoute path='/task-of-the-week' component={TaskOfTheWeek} />
                  <ProtectedRoute path='/tips-and-tricks' component={TipsAndTricks} />
                  <ProtectedRoute path='/python-installation' component={PythonInstallation} />
                  <ProtectedRoute path='/certificate' component={Certificate} />
                  <Route path='/not-found' component={NotFound} />
                  <Route path="/home" component={Homepage} />
                  <Route path="/" component={EntryPage} viewMode={true}/>

              
                  <Redirect to='/not-found' />
                </Switch>
                {location.pathname !== '/auth' && location.pathname !== '/change-password' && location.pathname !== '/confirm-user' && location.pathname !== '/' && <Footer />}
              </React.Fragment>
            </CSSTransition>
          </TransitionGroup>
          {/*<Cookies />*/}
        </React.Fragment>
       
      )} />
       </AppProvider>
    );
  }
}

export default App;
