import React, { Component } from 'react';

function FindUs() {
    return (
        <div className="contact">
            <h3>Hier finden Sie uns</h3>
            <ul>
                <li>Bundeswettbewerb KI</li>
                <li>Austrian Society for Artificial Intelligence (ASAI), Vienna</li>
                <li>Austrian register of associations (Österreichisches Vereinsregister):
                    <br />ZVR 435725315</li>
                <li className="half"><span>Email:</span><br /><a href="mailto:bwki@asai.ac.at">bwki@asai.ac.at</a></li>
            </ul>
        </div>
    )
}
export default FindUs;