import React, { Component } from 'react';
import authService from '../services/authService';
import { Link } from 'react-router-dom';
import * as alert from '../alert.json';

class aiSchool extends Component {
    state = {
        winners:[]
       
    }

    componentDidMount() {

        let winners = [
        {
            title:"HTL Rennweg",
            description:"Wien",
            subHeader:"GEWINNER 2023",
            link:""
        }
    ]

        this.setState({winners:winners})
    
    }

    removeAlert = () => {
        this.setState({
            showAlert: false
        })
    }
    render() {
        // const user = authService.getCurrentUser();

        // if (!user) this.props.history.push('/auth')
        return (
            <React.Fragment>
                <section className="main-content">

                    <h1 className="homepage header1 font-main-header text-center" style={{marginTop:"2rem"}}>KI-Schule des Jahres</h1>
                    <h3 className="homepage header2 font-sub-header text-center mb-0">Die Schule mit den meisten aktiv teilnehmenden Schüler*innen am KI-Kurs während der Wettbewerbszeit (März-Oktober) hat die Chance den Titel der KI-Schule des Jahres zu gewinnen.</h3>
                    <h3 className="homepage header2 font-sub-header text-center mt-0">Du willst mitmachen? Markiere bei der Registrierung oder im Anschluss in deinem Benutzerprofil, dass du teilnehmen möchtest und informiere uns an welcher Schule du Schüler*in bist.</h3>
                    {/* <div className="container-fluid home-wrapper"> */}
                        <div className="tiles-container aischool-tiles">
                        
                        {this.state.winners.length > 0 && this.state.winners.map( (school) => 
                                
                             {if (school.link === "") {
                                return (<div className="aischool-tile">
                                            <div className="tile-sub-header">{school.subHeader}</div>
                                            <div className="tile-header last-tile">
                                                    <div className="">{school.title}</div>
                                                    <div>{school.description}</div>
                                            </div>
                                        </div>)
                             }
                             else{
                                return (<a href={school.link} target="_blank" className="aischool-tile link">
                                            <div className="aischool-tile-inside">
                                                    <div className="tile-sub-header">{school.subHeader}</div>
                                                    <div className="tile-header">{school.title}</div>
                                                    <div className="tile-footer">{school.description}</div>
                                             </div>
                                        </a>)
                                }   
                             })
                        }
                          
                        </div>
                    {/* </div> */}

                    
                </section>
            </React.Fragment>
        );
    }
}

export default aiSchool;
