import { combineReducers } from 'redux'
import questions from './questionReducer'
import assignments from './codeSubmissionReducer';
import canSubmit from './canSubmitReducer'


const rootReducer = combineReducers({
    questions,
    assignments,
    canSubmit
})

export default rootReducer