import React, { Component } from 'react';
import authService from '../services/authService';
import { Link } from 'react-router-dom';
import * as alert from '../alert.json';

class Home extends Component {
    state = {
        paths:[],
        showAlert: false,
        showCountdown: true,
        countdown: '',
        showSchoolCountdown: true,
        schoolCountdown: '',
        showPanel:false,
        panel:1
    }

    componentDidMount() {

        let paths = [{
            title:"Woher Kommt KI?",
            description:"Lerne über die Hintergründe der KI und ihre Entwicklungen.",
            info:"In der Kategorie Meilensteine der KI werden die wichtigsten Schritte in der Entwicklung von KI behandelt. Personen, Leistungen und Events werden in den historischen Kontext eingebettet. Die Aufgaben aus dieser Kategorie eignen sich bestens für den Informatik-, NWT- Technik-, Ethik- und Geschichtsunterricht, ebenso wie für AGs. Der Inhalt der Aufgaben fördert das Allgemeinwissen rund um das Thema Künstliche Intelligenz.",
            link:"/milestones",
            image:"milestones.svg",
            styleClass:"primary-color-blue-background"
        },
        {
            title:"Wie baut man eine KI?",
            description:"Programmiere deine erste KI.",
            info:"In der Kategorie Programmieren werden die Grundlagen für die Programmierung einer KI vermittelt. Ein Grundlagenkurs in der Programmiersprache Python, KI-Programmiertutorials und Übungsaufgaben aus einem Aufgabenpool lassen sich hervorragend in den Informatikunterricht oder in Programmier-AGs einbinden.",
            link:"/programming",
            image:"programming.svg",
            styleClass:"primary-color-lightblue-background"
        },
        {
            title:"Wie ght man mit KI um?",
            description:"Diskutiere wie sich KI auf die Gesellschaft auswirkt.",
            info:"In der Kategorie Programmieren werden die Grundlagen für die Programmierung einer KI vermittelt. Ein Grundlagenkurs in der Programmiersprache Python, KI-Programmiertutorials und Übungsaufgaben aus einem Aufgabenpool lassen sich hervorragend in den Informatikunterricht oder in Programmier-AGs einbinden.",
            link:"/ethics",
            image:"ethics.svg",
            styleClass:"primary-color-lightgreen-background"
        }]

        this.setState({paths:paths})
    
    }

    removeAlert = () => {
        this.setState({
            showAlert: false
        })
    }

    toggleModal = (item) => {

        this.setState({
            showPanel:!this.state.showPanel,
            panel:1
        })
    }
    render() {
        const{showPanel,panel} = this.state
        const user = authService.getCurrentUser();
        let viewMode=true;
        // if (!user && viewMode) this.props.history.push('/auth')
        if(user)viewMode=false;

        return (
            <React.Fragment>
                <section className="content homepage">
                    <div>
                        <h1 className="homepage header1 font-main-header text-center">Hier lernst du die Grundlagen der KI.</h1>
                        <div className="homepage header2 font-sub-header text-center">Verstehe was KI ist, wo die Wurzeln liegen und wo sie uns im Alltag begegnet. Wie funktioniert KI und was sind die Grenzen? Lerne in Python zu programmieren und erarbeite dein erstes KI-Projekt.</div>
                    </div>
                    <div className="container-fluid home-wrapper">
                        <div className="path-tiles">
                        
                        {this.state.paths.length > 0 && this.state.paths.map( (path,index) => (
                             <Link to={`${path.link}`} key={index} className="homepage-tile layout">
                                 <div className="layout-wrapper">
                                    <img className="tile-image" src={`/images/icons/paths/${path.image}`} />
                                    <div className="tile-text"> {path.description}</div>
                                 </div>
                             </Link>))
                        }
                        <div className="homepage-bottom-option"><span onClick={this.toggleModal} className="primary-color-blue-foreground">FÜR LEHRER</span></div>
                        </div>
                       
                    </div>

                    { showPanel && 
                        <div className="overlay-panel-container">
                            <div className="gallery">
                                <div className="navigator back">
                                    {/* {!(panel === 1 ) && <img src="/images/icons/forward_arrow.svg" onClick={()=>{ this.setState({panel:panel-1})}} className="arrow back" />} */}
                                </div>
                                <div className="overlay-panel-home">
                                        <div className="section">
                                            {panel === 1 && 
                                                <p>
                                                Die Kursinhalte <strong>eignen sich u.a. für den Schulunterricht</strong>, 
                                                sowie den Einsatz in einer Vorlesung oder einer AG. 
                                                <br/>
                                                <br/>
                                                In der <strong>Kategorie Woher kommt KI?</strong> werden die wichtigsten Schritte in der Entwicklung von KI behandelt. 
                                                Die Aufgaben aus dieser Kategorie eignen sich bestens für den <string>Informatik-, NWT- Technik-, Ethik-, Religions- und Geschichtsunterricht</string>. 
                                                Der Inhalt der Aufgaben fördert das <strong>Allgemeinwissen</strong> rund um das Thema Künstliche Intelligenz. 
                                                <br/>
                                                <br/>
                                                In der <strong>Kategorie Wie baut man eine KI?</strong> lernt man schrittweise die Programmierung einer KI. 
                                                Die Inhalte eignen sich im Schulunterricht v.a. für die Fächer <strong>Informatik und Technik</strong>. 
                                                <br/>
                                                <br/>
                                                In der <strong>Kategorie Umgang mit KI</strong> setzt sich der Nutzer kritisch mit der Anwendung von KI in der Gesellschaft auseinander. 
                                                Ethische Grundsatzfragen stehen hier im Vordergrund. 
                                                Der Inhalt eignet sich bestens für den <strong>Ethik-, Religions-, Informatik-, Technik und NWT</strong>-Unterricht.
                                                </p>
                                            }    

                                        </div>
                                    <div className="close" onClick={this.toggleModal}></div>
                                </div>
                                <div className="navigator next">
                                    {/* {!(panel === 3 ) && <img src="/images/icons/forward_arrow.svg" onClick={()=>{ this.setState({panel:panel+1})}} className="arrow next" />} */}
                                </div>
                               
                            </div>
                            
                        </div>}
                                      
                       

                   
                </section>
            </React.Fragment>
        );
    }
}

export default Home;
