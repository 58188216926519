import http from './httpService';

const {
    REACT_APP_WEB_API_URL: web_api_url,
} = process.env;

export async function register(username, password, confirmPassword, school, postNumber, captcha, 
    student, teacher, others, fullName, email, parentEmail, is_ai_school, ageBelow18, ageAbove18, eventsCheck) {
        let ageBar = true;
        if(ageBelow18){
            ageBar = false;
        }
    return await http.post(`${web_api_url}/user`, {
        username,
        password,
        confirmPassword,
        school,
        postNumber,
        captcha,
        student,
        teacher,
        others,
        fullName,
        //birthday,
        email,
        parentEmail,
        is_ai_school,
        ageBar,
        eventsCheck
    })
}

export async function changePassword(password, confirmPassword, token) {
    return await http.post(`${web_api_url}/change-password`, { password, confirmPassword, token })
}

export async function getUser(id) {
    return await http.get(`${web_api_url}/user/${id}`);
}

export async function getAttemptedTasksForCourse(course) {
    
    return await http.get(`${web_api_url}/user/task_attempts/${course}`);
}

export async function getScoresForCourse(course) {
    
    return await http.get(`${web_api_url}/user/scores/${course}`);
}

export async function getUserScores(course) {
    return await http.get(`${web_api_url}/user/scores`);
}

export async function editUser(id, fullName, postNumber,school, is_ai_school){
    return await http.put(`${web_api_url}/user/${id}`, { fullName, postNumber, school, is_ai_school})
}

export async function leaveTeam(teamName) {
    return await http.post(`${web_api_url}/team-leave/${teamName}`)
}




export default {
    register,
    changePassword,
    getUser,
    editUser,
    leaveTeam,
    getAttemptedTasksForCourse,
    getScoresForCourse,
    getUserScores
}