import React from 'react'

export default function loading(props) {
    return (
        <div className="loading-container">
            <div className="loading-panel">
                {props.message?props.message:"Loading..."}
            </div>
            
        </div>
    )
}
