import React, { Component } from 'react';
import { allQuestions } from '../../questions.json';
import assignmentsService from '../../services/assignmentsService';
import questionService from '../../services/questionService';
import userService from '../../services/userService'
import authService from '../../services/authService'
import { element, number } from 'prop-types';
import ViewModeAlert from '../viewModeAlert'
import Loading from '../common/loading'


class CourseMain extends Component {
    constructor(props) {
        super(props);
        const {course} = this.props.match.params
        this.state = {
            viewMode:true,
            showViewModeMessage:false,
            assignments: [],
            course:course,
            root:course === 'python'? '/programming/pythonTutorials':'/programming/tutorials',
            index: {
                index1: 0,
                index2: 0,
                index3: 0,
                index4: 0,
                index5: 0,
            },
            response: [],
            chapterNames:[
                            {"python":[
                                'Einführung',
                                'Hello World!',
                                'Datentypen',
                                'Boolesche Logik',
                                'Verzweigungen',
                                'Schleifen',
                                'Funktionen',
                                'Listen',
                                'Dictionaries',
                                'Objektorientierung',
                                'Werde zum Überflieger'
                            ]
                            }
                ],
            courseMetaData:null,
            showLoading:true,
            
        }
    }

    componentDidMount() {

        let user = authService.getCurrentUser();

        if(!user){

            assignmentsService.getCourseMetadta(this.state.course).then(({data:response}) =>{
                this.setState({
                    courseMetaData:response,
                    viewMode:true,
                    showLoading:false})
            }).catch(err => {
                this.setState({viewMode:true,showLoading:false})
            })
        }
        else{
            assignmentsService.getAssignments(this.state.course).then(({ data: response }) => {

                userService.getScoresForCourse(this.state.course).then(({data:responsePoints}) => {
                
                    let allchapters = response.assignments.map( p => p.id.split("_")[0]).filter(item => !isNaN(item))
                    allchapters = [...new Set(allchapters)]
                    allchapters.sort((a,b) => Number(a) - Number(b))
                    let chapters=[]
                    
                    let allAssignments = response.assignments;
        
                    allAssignments = allAssignments.map(assingment => { 
                        let scoreObj = responsePoints.find( pointsItem => pointsItem.chapter_id === assingment.id)
                        // let scoreObj = null;
                        assingment.score = scoreObj? scoreObj.points:0;
                        return assingment })

                    allchapters.forEach(chapter => {
                        chapters[Number(chapter)] = response.assignments.filter(p => p.id.split('_')[0] === chapter)
                    });

                    this.setState({
                        assignments: [].concat(response.assignments),
                        chapters: chapters,
                        viewMode:false,
                        showLoading:false
                     })
                })
                 return;
                
            }).catch(err => { })
            questionService.getMultipleQuestionsByType(this.state.course).then(({ data: response }) => {
                this.setState({
                    response
                })
            })
        }       
    }

    //TODO: refactor
    onChapterClick = (element) => {
        let root = this.state.root       
        if (element[0].video && element[0].video === true) {
            this.props.history.push({
                pathname: `${root}/chapter-video/course=${this.state.course}/id=${element[0].id}`,
            })
        }
        else if( element[0].intro){
            this.props.history.push({
                pathname: `${root}/intro-page/course=${this.state.course}/id=${element[0].id}`,
            })
        }
        else if(element[0].course_data){
            this.props.history.push({
                pathname: `${root}/course-content/course=${this.state.course}/id=${element[0].id}`,
            })
        }
        else if (element[0].questions && element[0].questions !== null) {
            let allQuestions = JSON.parse(element[0].questions);
            this.props.history.push({
                pathname: `${root}/code-questions/course=${this.state.course}/id=${element[0].id}/questionId=${allQuestions.allQuestions[0].id}`
            })
        }
        else if (element[0].description && element[0].description !== null) {
            this.props.history.push({
                pathname: `${root}/code-submission/course=${this.state.course}/id=${element[0].id}`
            })
        }
        else if(element[0].end){
            this.props.history.push({
                pathname: `${root}/conclusion-page/course=${this.state.course}/id=${element[0].id}`,
            })
        }
    }

    //TODO: refactor
    onQuestionClick = (event, item) => {
        let root = '/programming/pythonTutorials'  
        let element = this.handleVideoInChapter(item);

        if (element.video && element.video === true) {
            this.props.history.push({
                pathname: `${root}/chapter-video/course=${this.state.course}/id=${element.id}`,
            })
        }
        else if(element.intro && element.intro !== null){
            this.props.history.push({
                pathname: `${root}/intro/course=${this.state.course}/id=${element.id}`
            })

        }
        else if(element.course_data){
            this.props.history.push({
                pathname:`${root}/course-content/course=${this.state.course}/id=${element.id}`
            })
        }
        else if (element.questions && element.questions !== null) {
            // let allQuestions = JSON.parse(element.questions);
            this.props.history.push({
                pathname: `${root}/code-questions/course=${this.state.course}/id=${element.id}/questionId=1`
            })
        }
        else if (element.description && element.description !== null) {
            this.props.history.push({
                pathname: `${root}/code-submission/course=${this.state.course}/id=${element.id}`
            })
        }
        else if(element.end && element.end !== null){
            this.props.hisotry.push({
                pathname:`${root}/conclusion-page/course=${this.state.course}/id=${element.id}`
            })
        }

        if(!event)
            var event = window.event;
            event.cancelBubble = true;
        if (event.stopPropagation) 
            event.stopPropagation();
    }
    
    toggleViewModeMessage = () => {
        this.setState({
            showViewModeMessage:!this.state.showViewModeMessage
        })
    }

    onRegister = () => {
        this.props.history.push({
          pathname: `/auth`
        })
        this.setState({
            showViewModeMessage:false
        })
      }
    

    handleVideoInChapter(element) {

        let ids = element.id.split("_");
        let chapterId = Number(ids[0]);
        let subChapterId = Number(ids[1]);
        let currentChapter = this.state.chapters[Number(chapterId)];
        let videoItem = currentChapter.filter((item) => {
            let ids = item.id.split('_');
            if (ids.includes('v') && Number(ids[1]) === subChapterId-1){
                return true;
            }   
            else
                return false;
        });
        
        if(videoItem && videoItem[0])
            return videoItem[0];
        else
            return element
    }

    render() {
        const {  response, chapters, chapterNames,course, viewMode, courseMetaData, showViewModeMessage, showLoading } = this.state;
        return (
            <React.Fragment>
                <div className="content tutorial-landing">
                    {!showLoading && <div className={`grid-box multiple-choice ${course === 'recycling'?'narrow-tile-container':''}`}>
                        
                        {(!viewMode) ?
                            (chapters && chapters.map((chapter,index) => {
                                if(chapter)
                                    return(
                                    <div className={`${course === 'recycling'?'':'dog-job-box'} job-box margin-top-10`} onClick={this.onChapterClick.bind(this, chapter)} >
                                        <div className={`image-box`}  >
                                            <img  src={`/images/icons/subpaths/programming/pythonTutorials/pythonCourse/${course}_${index}.svg`} alt="" />
                                        </div>
                                        <div className={`content-box`}>
                                            <span className={`chapter`} >{`KAPITEL ${index}`}</span>
                                            <span className={`title primary-color-lightblue-foreground`} >{ chapterNames[0].python[index-1]}</span>
                                            <div className={`section`}>
                                                <span>Abschnitt</span>
                                                <span>Punkte</span>
                                            </div>
                                            <div className={`question-section`}>
                                                { 
                                                    chapter.map((element, index1) =>
                                                        (element.questions || element.intro || element.course_data || element.description || element.end) &&
                                                        <React.Fragment>
                                                            <div key={index1} className={`question`} onClick={ (e) => {this.onQuestionClick(e, element)}}>
                                                                {/* <div className={`line`}></div> */}
                                                                <span style={{paddingRight:'10px'}}>{element.title} </span>
                                                                {(element.questions || element.description) && <span>{element.score}</span>}
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </div>
                                        </div>
                                </div>)
                            })):
                                (courseMetaData && courseMetaData.map((chapter,index) => {

                                    if(chapter)
                                        return(
                                        <div className={`${course === 'recycling'?'':'dog-job-box'} job-box margin-top-10`} onClick={this.toggleViewModeMessage} >
                                            <div className={`image-box`}>
                                                <img src={`/images/icons/subpaths/programming/pythonTutorials/pythonCourse/${course}_${index+1}.svg`} alt="" />
                                            </div>
                                            <div className={`content-box`}>
                                                <span className={`chapter`} >{`KAPITEL ${index+1}`}</span>
                                                <span className={`title primary-color-lightblue-foreground`} >{ chapter.chapter}</span>
                                                <div className={`section`} >
                                                    <span>Abschnitt</span>
                                                </div>
                                                <div className={`question-section`}>
                                                    { 
                                                        chapter.sub_chapters && chapter.sub_chapters.map((element, index1) =>
                                                            <React.Fragment>
                                                                <div key={index1} className={`question`} >
                                                                    {/* <div className={`line`}></div> */}
                                                                    <span style={{paddingRight:'10px'}}>{element} </span>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                    </div>)

                                } 
                            ))
                        }
                    </div>}
                    {showViewModeMessage && <ViewModeAlert onRegister={this.onRegister} onCancel={this.toggleViewModeMessage} />}
                    {showLoading && <Loading />}
                </div>
                
            </React.Fragment>
        )
    }
}

export default CourseMain;