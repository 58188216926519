import React, { Component } from 'react';
import { allQuestions } from '../../questions.json'
import questionService from '../../services/questionService';
import userService from '../../services/userService';
import authService from '../../services/authService';
import ViewModeAlert from '../viewModeAlert'

class MultipleChoiceGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMode:true,
            showViewModeMessage:false,
            course:this.props.match.params.course,
            chapter1: {},
            chapter2: {},
            chapter3: {},
            chapter4: {},
            chapter5: {},
            chapter6: {},
        }
    }

    componentDidMount(){

        let user = authService.getCurrentUser();

        if(!user){
            this.setState({viewMode:true})
        }
        else{
    
            questionService.getMultipleQuestionsByType('ki_and_society').then(({ data: response }) => {
                this.setState({
                    viewMode:false,
                    chapter1: response.filter(p => p.chapter_id === '1'),
                    chapter2: response.filter(p => p.chapter_id === '2'),
                    chapter3: response.filter(p => p.chapter_id === '3'),
                    chapter4: response.filter(p => p.chapter_id === '4'),
                    chapter5: response.filter(p => p.chapter_id === '5'),
                    chapter6: response.filter(p => p.chapter_id === '6'),
                })
            })

            
        }
       
    }

    onChapterClick = (event,chapterId, questionId) => {

        if(this.state.viewMode){
            this.toggleViewModeMessage()
        }
        else {
            this.props.history.push(`/ethics/course=${this.state.course}/chapter=${chapterId}/id=${questionId}`)
        }
        if (!event) var event = window.event;
             event.cancelBubble = true;
        if (event.stopPropagation) 
            event.stopPropagation();
            
    }

    onChapterTileClick = (event, chapterId, questionId) => {
        if(this.state.viewMode){
            this.toggleViewModeMessage()
        }
        else {
            this.props.history.push(`/ethics/course=${this.state.course}/chapter=${chapterId}/id=${questionId}`)
        }
        if (!event) var event = window.event;
            event.cancelBubble = true;
        if (event.stopPropagation) 
            event.stopPropagation();
        
    }

    toggleViewModeMessage = () => {
        this.setState({
            showViewModeMessage:!this.state.showViewModeMessage
        })
    }

    onRegister = () => {
        this.props.history.push({
          pathname: `/auth`
        })
        this.setState({
            showViewModeMessage:false
        })
      }

    render() {
        const{showViewModeMessage} =this.state
        return(
            <React.Fragment>
                <div className={`grid-box multiple-choice content`} >
                    {
                        allQuestions.map((element, index) => 
                            <div key={index} className={`job-box aisociety-job-box`} onClick={(e) => {this.onChapterTileClick(e, element.chapter, element.questions[0].id)}} >
                                <div  className={`image-box ai-scoiety`} >
                                    <img src={element.image} alt=""/>
                                </div>
                                <div className={`content-box`}>
                                    <span className={`chapter chapter-padding`} >KAPITEL {element.chapter}</span>
                                    <span className={`title primary-color-lightgreen-foreground`}  >{element.title}</span>
                                    <div className={`section`} >
                                        <span>Abschnitt</span>
                                        {!this.state.viewMode && <span>Punkte</span>}
                                    </div>
                                    <div className={`question-section`}>
                                        {
                                            element.questions.map((element1, index1) =>
                                                <div key={index1} className={`question`} onClick={(e) => {this.onChapterClick(e, element.chapter, element1.id)}} >
                                                    {/* <div className={`line`}></div> */}
                                                    <span style={{paddingRight:'10px'}}>{element1.title} </span>
                                                    {!this.state.viewMode && 
                                                        (this.state[`chapter${index+1}`][0] ?
                                                            <span>{this.state[`chapter${index+1}`].find(p => p.quiz_id=== element1.id)&&this.state[`chapter${index+1}`].find(p => p.quiz_id=== element1.id).correct_answers_count ? this.state[`chapter${index+1}`].find(p => p.quiz_id=== element1.id).correct_answers_count: '0'}</span>
                                                            :
                                                            <span>0</span>)
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>  
                            </div>
                        )
                    }
                     {showViewModeMessage && <ViewModeAlert onRegister={this.onRegister} onCancel={this.toggleViewModeMessage} />}
                </div>            
            </React.Fragment>
        )
    }
}

export default MultipleChoiceGrid;