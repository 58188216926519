import React, { Component } from 'react';
import logo from '../../img/logo_bwki.png';
import { Link } from 'react-router-dom';
import Breadcrumb from './breadcrumb';
import authService from '../../services/authService';

class AppBanner extends Component {

  state = {
    showMenu:false,
    showBreadcrumbMenu:false,
    wire_image: '/images/icons/menu_icon.svg'
  }

  toggleMenu = () =>{
    var wire_image = ''; 
    if (!this.state.showMenu){
      wire_image = '/images/icons/menu_open.svg';
    }
    else{
      wire_image = '/images/icons/menu_icon.svg';
    }
    
    this.setState({showMenu:!this.state.showMenu,
      wire_image:wire_image})
  }

  closeMenus = () => {
    let wire_image = '/images/icons/menu_icon.svg';
    this.setState({
      showMenu:false,
      showBreadcrumbMenu:false,
      wire_image:wire_image
    })

  }

  toggleBreadcrumbMenu = () =>{
    this.setState({
            showBreadcrumbMenu:!this.state.showBreadcrumbMenu
          })
  }


  render() {
    const { role } = this.props;
    const user = authService.getCurrentUser();
    let viewMode = user?false:true;
    return (
      <React.Fragment>
        <section className="app-banner-wrapper">
          <div className="container-fluid">
            <div className={`header-wrapper ${role ? 'entry' : ''}`}>
              {role &&
                <div className="nav-logo">
                  <Link to='/home'><img alt='logo' src={logo} /></Link>
                  <Link to='/home'><h1> <span>Kurs</span> <br /> Künstliche Intelligenz </h1></Link>
                </div>
              }
              {!role &&
                <div className="banner-grid">
                  <div className="banner-logo">
                    <Link to='/home'><img alt='logo' src={logo} /></Link>
                    <Link to='/home'><h1> <span>Kurs</span> <br /> Künstliche Intelligenz </h1></Link>
                  </div>
                  <div className="breadcrumb-panel">
                    <Breadcrumb path={this.props.path} toggleBreadcrumbMenu={this.toggleBreadcrumbMenu} />
                  </div>

                 { !viewMode &&  <img onClick={this.toggleMenu} className="menu-right" src={this.state.wire_image} />}
                 { viewMode && <Link to='/auth' className="login-btn">Anmeldung</Link>}

                </div>
              }
              
            </div>
          </div>
        </section>
        {this.state.showMenu && 
          <div className="app-banner-dropdown">
            <ul>
                      <li><Link to='/home' onClick={this.toggleMenu}>Startseite</Link></li>
                      <li><Link to='/user-profile' onClick={this.toggleMenu}>Benutzerprofil</Link></li>
                      <li><Link to='/my-status' onClick={this.toggleMenu}>Punkteübersicht</Link></li>
                      <li><Link to='/school-of-the-year' onClick={this.toggleMenu}>KI-Schule des Jahres</Link></li>
                      <li><Link to='/certificate' onClick={this.toggleMenu}>Zertifikat</Link></li>
                      <li><Link to='/tips-and-tricks' onClick={this.toggleMenu}>Tipps & Tricks</Link></li>
                      <li><a href="https://ki-wettbewerb.asai.ac.at" onClick={this.toggleMenu} target="_blank">Bundeswettbewerb KI</a></li>
                      {!viewMode && <Link to='/logout' className="logout">Abmelden</Link>}
                      {viewMode && <Link to='/auth' className="logout">Anmeldung</Link>}
            </ul>
          </div> 
        }

        {this.state.showBreadcrumbMenu && 
          <div className="app-banner-dropdown breadcrumb-dropdown">
            <ul>
                      <li><Link to='/milestones' onClick={this.toggleBreadcrumbMenu}>Woher kommt KI</Link></li>
                      <li><Link to='/programming' onClick={this.toggleBreadcrumbMenu}>Wie baue ich eine KI</Link></li>
                      <li><Link to='/ethics' onClick={this.toggleBreadcrumbMenu}>Umgang mit KI</Link></li>
                    
            </ul>
          </div> 
        }

        {(this.state.showMenu || this.state.showBreadcrumbMenu) && 
          <div className="overlay-panel-container" onClick={this.closeMenus}></div>

        }
      </React.Fragment>
    );
  }
}

export default AppBanner;
