import React from 'react';
import { FilePond, File } from 'react-filepond';
import MyTooltip from './my-tooltip'
import 'filepond/dist/filepond.min.css';

const UploadInput = ({ name, label, value, onChange, error = false, horizontal = true, tooltip = false, message = "", maxFiles = null }) => {
    var divClass = 'file-upload-wrapper';
    var parentDiv = "form-group";
    var labelClasses = '';
    if (horizontal) {
        divClass += ' col-sm-9';
        labelClasses += ' col-sm-3 col-form-label'
        parentDiv += ' row';
    }
    if (error) divClass += ' error'
    const data = value;

    return (
        <div className={parentDiv}>
            <label className={labelClasses}>
                {label}
                {tooltip && <MyTooltip name={name} message={message} />
                }
            </label>
            <div className={divClass}>
                <FilePond allowMultiple={true}
                    maxFiles={maxFiles}
                    beforeAddFile={(file) => { if (!file.fileType.includes('image/') || ((file.fileSize / 1000000) > 10)) return false; }}
                    onupdatefiles={(fileItems) => {
                        onChange({ currentTarget: { type: 'file', name: name, value: fileItems.filter(file => file.fileType.includes('image/')).map(fileItem => fileItem.file) } })
                    }
                    }>

                    {data.map(file => (
                        <File key={file} src={file} origin="local" />
                    ))}
                </FilePond>
            </div>
        </div>
    )
}

export default UploadInput;