import http from './httpService';
import { getUserName } from './authService'

const submission_api_url = process.env.REACT_APP_SUBMISSIONS_API_URL
const web_api_url = process.env.REACT_APP_WEB_API_URL

export async function getCodeforAssignment(course,assignment) {
    return await http.get(`${web_api_url}/code/${course}/${assignment}`);
}

export async function getCodeforAssignmentByFileId(course,assignment,fileIds) {
    console.log("get code by file ids args",course,fileIds)
    // If you do not pass params it will turn to get all content for assignment request
    return await http.get(`${web_api_url}/code/${course}/${assignment}`,{"params":{
        "file_ids":fileIds }
    });
}

export async function saveCodeForField(course,assignment,fileId, content){
    console.log(course, assignment,[fileId], content)

    return await getCodeforAssignmentByFileId(course,assignment,[fileId]).then(async ({data:response}) => {
        console.log(response)
        if(response && response.output && response.output.length > 0 ){
            return await http.put(`${web_api_url}/code/${course}/${assignment}`, {
                "file_id":fileId,"content":content
            })
        }
        else{
            console.log("post")
            return await http.post(`${web_api_url}/code/${course}/${assignment}`, {
                "file_id":fileId,"content":content
            })
        }
    })
    
    
}


export default {
    getCodeforAssignment,
    saveCodeForField,
    getCodeforAssignmentByFileId
}