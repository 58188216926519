import React, { Component } from 'react';

function Quote() {
    return (
        <section className="womix__quote-module">
            <div className="content-wrapper">
                <div className="text-wrapper">
                    <h2 className="womix__gradient"> Those who can imagine anything, can create the impossible. </h2>
                    <p>– Alan M. Turing, <br /> britischer Mathematiker und Kryptoanalytiker</p>
                </div>
            </div>
        </section>
    )
}

export default Quote;