import React, { Component } from 'react';
import authService from '../services/authService';
import { Link } from 'react-router-dom';
import * as alert from '../alert.json';

class memory extends Component {
    state = {
        paths:[]

    }

    componentDidMount() {

        let paths = []

        this.setState({paths:paths})
    
    }

    removeAlert = () => {
        this.setState({
            showAlert: false
        })
    }
    render() {
        const user = authService.getCurrentUser();

        if (!user) this.props.history.push('/auth')
        return (
            <React.Fragment>
                <section className="content homepage">
                    <div>
                        <h1 className="homepage header1 font-main-header text-center">Milestones</h1>
                        {/* <div className="homepage header2 font-sub-header text-center">Verstehe was KI ist, wo die Wurzeln liegen und wo sie uns im Alltag begegnet. Wie funktioniert KI und was sind die Grenzen? Lerne in Python zu programmiren und erarbeite dein erstes KI-Projekt.</div> */}
                    </div>
                   

                   
                </section>
            </React.Fragment>
        );
    }
}

export default memory;
