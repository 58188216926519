import React from 'react';

const PythonInstallation = () => {
    return (
        <React.Fragment>
            <section className="content question">
                    <div className="container-fluid">
                        <div className="container-limit my-limit">
                        <div className="tasks">
            <div className="title">
                 <span>Lokale Python-Installation aufsetzen</span>
            </div>
            <p className='tasks-message'>
            Möglicherweise funktioniert Python 3 auf deinem System bereits.
            Verwende Python 3 für die Aufgaben, denn der Umgang mit Sonderzeichen (Umlaute etc.) ist in Python 2 nicht besonders komfortabel.
            Überprüfe zunächst deine Python Version; dazu musst du zunächst ein Terminal öffnen:
            </p>

            <p className='tasks-message'>
            <ul>
            <li>Windows: öffne das Startmenü, indem du die "Windows"-Taste drückst. Suche dann nach "cmd" und starte die "Eingabeaufforderung" ("cmd.exe").</li>
            <li>MacOS: Öffne ein Terminal, indem du "Command+Leertaste" drückst und nach "Terminal" suchst. Starte das Terminal mit einem Doppelklick.</li>
            <li>Linux: öffne ein Terminal (auf Ubuntu und ähnlichen Systemen mit "Strg+Alt+T", auf kde-Systemen mit "Alt+F2" und gib dann "konsole" ein).</li>
            </ul>

            Führe dann den folgenden Befehl aus: "python3 --version" (oder wenn dies eine Fehlermeldung wirft, versuche es mit "python --version").
            Wenn du bei einem der Befehle keine Fehlermeldung bekommst und in der Konsole etwas ähnliches steht wie "Python 3.7.5", kannst du den dazugehörigen Befehl ab nun zum Starten von Python 3 verwenden.
            Wichtig: wenn hier etwas wie "Python 2.7.17" steht, hast du eine Installation der Version 2, welche für die Aufgabe nicht verwendet werden kann.
            Wenn du beidesmal eine Fehlermeldung bekommst, gehe direkt zur Installation.
            </p>

            <p className='tasks-message'>
            Ab hier wird die Aufgabe mit dem Befehl "python3" gestellt, auf deinem System kann aber auch der Befehl "python" die korrekte Installation der Version 3 starten (wie zuvor beschrieben).
            Führe nun den folgenden Befehl aus, um zu testen, ob du das GUI-Toolkit Tkinter installiert hast: "python3 -m tkinter".
            Wenn du ein kleines Testfenster siehst, hast du Python und Tkinter bereits korrekt installiert.
            </p>

            <p className='tasks-message'>
            Wenn du eine Fehlermeldung bekommen hast, lade dir Python (mit Tkinter) kostenfrei hier herunter:
            </p>

            <p className='tasks-message'>
            <ul>
            <li>Windows: <a href='https://www.python.org/ftp/python/3.8.2/python-3.8.2-amd64.exe' >https://www.python.org/ftp/python/3.8.2/python-3.8.2-amd64.exe</a></li>
            <li>MacOS: <a href='https://www.python.org/ftp/python/3.8.2/python-3.8.2-macosx10.9.pkg' >https://www.python.org/ftp/python/3.8.2/python-3.8.2-macosx10.9.pkg</a></li>
            <li>Linux: auf den meisten Linux-Systemen ist Python bereits vorinstalliert.
            Jedoch fehlt meistens das Modul "tkinter".
            Auf Ubuntu-ähnlichen Systemen kannst du dieses mit diesem Befehl nachinstallieren: "sudo apt-get install python3-tk".
            Für andere Linux-Systeme, folge bitte diesen Lösungsvorschlägen:
            <a href='https://stackoverflow.com/questions/4783810/install-tkinter-for-python' >https://stackoverflow.com/questions/4783810/install-tkinter-for-python</a></li>
            </ul>
            Jetzt sollte deine Python 3-Installation mit Tkinter funktionieren.
            Um dies zu testen, kopiere die folgende Vorlage in eine neue leere Datei "breakout.py".
            Achte darauf, dass du in deinem Editor zum Abspeichern "UTF-8" als Encoding verwendest, sonst funktionieren die Umlaute und Sonderzeichen in der deutschen Sprache nicht.
            </p>

            <p className='tasks-message'>
            Mögliche Editoren zum Bearbeiten von Python-Skripten sind etwa: PyCharm, Notepad++, Sublime Text, Atom Editor, Eclipse mit Python Plugins, vim (Linux und MacOS), gedit (Linux).
            Aber die Wahl deines Lieblingseditors steht dir frei.
            </p>

            <p className='tasks-message'>
            Auf Windows solltest du die Datei mit einem Doppelklick ausführen können.
            Auf anderen Systemen musst du mit dem Terminal in das Verzeichnis wechseln, in dem diese neue Datei liegt.
            Das kannst du mit dem Befehl "cd " machen.
            Dann kannst du die Datei, z.B. das Skript aus der Breakout-Aufgabe, mit "python3 breakout.py" ausführen.
            Achte darauf, dass du Python 3 verwendest.
            </p>


                </div>
                </div>
                </div>
            </section>
        </React.Fragment>
    );
}
export default PythonInstallation;
