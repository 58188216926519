import { CAN_SUBMIT } from '../constants/can-submit';

const initialState = false

export default function canSubmit(state = initialState, action) {
    switch (action.type) {
        case CAN_SUBMIT:
            return action.canSubmit
        default: {
            return state;
        }
    }
}