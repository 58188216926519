import React, { Component } from 'react';
import certificateService from '../../services/certificateService';
class Certificate extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            url: '',
            error: false,
            isLoading: false,
            success: false
        }
    }
    componentDidMount() {
        this.getCertificate();
    }

    getCertificate = () => {
        certificateService.getCertificate().then(({ data }) => {
            this.setState({
                success: data.success,
                url: data.url
            })
        }).catch(err => {
            if (this._isMounted) {
                this.setState({
                    error: "Something unexpected happened"
                })
            }
        })
    }

    render() {
        const {success, url, error} = this.state;
        if (success) return (
        <React.Fragment>
            <section className="content question">
                    <div className="container-fluid">
                        <div className="container-limit my-limit">
                       
                            <div className="bestenliste">
                               
                                <p className='certificate'><a className='red' href={url} target="_blank">Hier</a> kannst du ein Zertifikat für deine erbrachte Leistung generieren.</p>

                            </div>
                        </div>
                    </div>
            </section>
        </React.Fragment>
        )
        else return (
            <React.Fragment>
                <section className="content question">
                    <div className="container-fluid">
                        <div className="container-limit my-limit">
                            <div className="bestenliste">
                                {
                                error === false &&
                                <p className='certificate'>Du musst erst den Kurs abschließen, bevor du Dein Zertifikat herunterladen kannst.</p>
                                 }
                                {
                                error === 'Something unexpected happened' &&
                                <p className='certificate'>Es ist ein Fehler aufgetreten. Bitte schreib uns eine Nachricht!</p>
                                 }
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Certificate;