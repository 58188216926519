import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({ href = "#", label = 'Button', className = "", onClick = null, disabled = false }) => {
    return (
        <React.Fragment>
            {(href === '#') && <button disabled={disabled} type='button' onClick={onClick} className={className}>{label}</button>}
            {(href !== '#') && <Link disabled={disabled} to={href} onClick={onClick} className={className}>{label}</Link>}
        </React.Fragment>
    );
}

export default Button;