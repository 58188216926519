import React from 'react';
import MyTooltip from './my-tooltip'

const UploadImageInfo = ({ label, id, horizontal = true, tooltip = false, message = "" }) => {
    var divClass = 'file-image-info-wrapper text-left';
    var parentDiv = "form-group";
    var labelClasses = '';
    if (horizontal) {
        divClass += ' col-sm-9';
        labelClasses += ' col-sm-3 col-form-label'
        parentDiv += ' row';
    }

    return (
        <div className={parentDiv}>
            <label className={labelClasses}>
                {label}
                {tooltip && <MyTooltip name={id} message={message} />
                }
            </label>
            <div className={divClass}>
                If you want to delete the old image, upload another one.
            </div>
        </div>
    )
}

export default UploadImageInfo;