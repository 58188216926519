import assignmentsService from '../services/assignmentsService';
import * as types from '../constants/codeSubmisstionTypes'

export const getAssignments = (course) => {
    return dispatch => {
        let assignments = [];
        let index = 0;
        assignmentsService.getAssignments(course).then(({ data }) => {
            assignments = data.assignments;
            assignments.forEach((assignment, i) => {
                assignment.results = [];
                if (!assignment.video) {
                    assignmentsService.getUserAssignment(course,assignment.id).then(({ data: assignmentData }) => {
                        assignment.previous_submissions = assignmentData.previous_submissions;
                        assignment.code = assignmentData.code;
                        assignment.results = assignmentData.results;

                        index++;
                        if (index === assignments.length - 1) {
                            dispatch({
                                type: types.GET_ASSIGNMENTS,
                                assignments
                            })
                        }

                    }).catch(ex => {
                        index++;
                        if (index === assignments.length - 1) {
                            dispatch({
                                type: types.GET_ASSIGNMENTS,
                                assignments
                            })
                        }
                    })
                }
                else {
                    index++;
                    if (index === assignments.length - 1) {
                        dispatch({
                            type: types.GET_ASSIGNMENTS,
                            assignments
                        })
                    }
                }
            });
        }).catch(err => {
            dispatch({
                type: types.GET_ASSIGNMENTS,
                assignments
            })
        })
    }
}

export const editAssignment = (course, id, code) => {
    return dispatch => {
        dispatch({
            type: types.EDIT_ASSIGNMENT,
            course,
            id,
            code  
        })
    }
}

export const resetAssignment = (course, id) => {
    return dispatch => {
        assignmentsService.resetUsersAssignmentCode(course,id).then(({ data }) => {
            dispatch({
                type: types.RESET_ASSIGNMENT_CODE,
                course,
                id,
                code: data.code,
            });
        }).catch(err => {

            dispatch({
                type: 'DEFAULT'
            })
        })
    }
}

export const goToNext = (nextUrl) => {
    return dispatch => {
        dispatch({
            type: types.GO_TO_NEXT,
            nextUrl
        })
    }
}

export const submitAssignment = (course, id, code, nextUrl) => {
    return dispatch => {
        assignmentsService.submitUsersAssingmentCode(course, id, code).then(({ data }) => {
            dispatch({
                type: types.SUBMIT_ASSIGNMENT_CODE,
                course,
                id,
                results: data.results,
                nextUrl
            });
            assignmentsService.submitAssignmentResults(course, id, data.results);
        }).catch(err => {

            dispatch({
                type: 'DEFAULT'
            })
        })
    }
}