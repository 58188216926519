import React,{useState,useEffect,useHistory} from "react";
import { tasks } from "../../tasks.json";
import easy from "../../img/difficulty_1.svg";
import medium from "../../img/difficulty_2.svg";
import hard from "../../img/difficulty_3.svg";
import schueler from "../../img/Schueler_Task.svg";
import authService from "../../services/authService";
import ViewModeAlert from '../viewModeAlert'

const TaskArchive = (props) => {

  const [difficulty, setDifficulty] = useState(0)
  const [category, setCategory] = useState('')
  const [tasksList, setTasksList] = useState(tasks)    
  const [categories, setCategories] = useState([])
  const [showViewModeMessage, setShowViewModeMessage] = useState(false)
  const [viewMode,setViewMode] = useState(true)


  const filterCategory=(category)=>{
    setCategory(category)
    let filteredList = category === '' ? tasks.filter(task => difficulty === 0 || task.difficulty===difficulty ):
    tasks.filter(task => task.category === category && (difficulty === 0 || difficulty === task.difficulty))
    setTasksList(filteredList)
  }

  useEffect(() => {
    const allCategories = [...(new Set(tasks.map(task => { return task.category})))]  
    setCategories(allCategories)
    let user = authService.getCurrentUser()
    if(!user)
      setViewMode(true)
    else
      setViewMode(false)

    return () => {
      
    }
  }, [''])

  const filterDifficulty =(difficulty) => {
      setDifficulty(difficulty)
      let filteredList = difficulty === 0 ? tasks.filter(task => category === '' || task.category===category):
                                            tasks.filter(task => task.difficulty === difficulty && (category === '' || category === task.category))
      setTasksList(filteredList)
  }

  const onTaskClicked = (task) => {
    
    if(viewMode){
      toggleViewModeMessage()
    }
    else{
      props.history.push('/programming/task-archive/task/'+task.title);
    }
    
  }

  const onRegister = () => {
    props.history.push({
      pathname: `/auth`
    })
    setShowViewModeMessage(false)
  }


  const toggleViewModeMessage = () => {
      setShowViewModeMessage(!showViewModeMessage)
  }

  return (
    <React.Fragment>
      <section className="content question">
        <div className="container-fluid">
        <div className="homepage header2 font-sub-header task-archive-header text-center mt-5">Hier findet ihr Programmieraufgaben in verschiedenen Schwierigkeitsstufen. Wenn ihr Python lokal auf eurem Rechner installieren möchtet, findet ihr <a className="primary-color-lightblue-foreground" href="https://www.python.org/" target="_blank" >hier</a> eine Anleitung.</div>

          <div className="container-limit my-limit">
          
            <div className="tasks">
              <div className="task-selector">
                <div className="task-difficulty">
                  <h3>Schwierigkeitsstufen</h3>
                  <img onClick={filterDifficulty.bind(this,1)} className={difficulty ===1?'selected':''} alt="Leicht" src={easy} />
                  <img onClick={filterDifficulty.bind(this,2)} className={difficulty ===2?'selected':''}alt="Mittel" src={medium} />
                  <img onClick={filterDifficulty.bind(this,3)} className={difficulty ===3?'selected':''}alt="Schwierig" src={hard} />
                  {difficulty !== 0 && <div className="clear" onClick={filterDifficulty.bind(this,0)}>X</div>}
                </div>
                <div className="task-category">
                  <h3>Kategorie</h3>
                  {categories.map(categoryItem => (<p className={category===categoryItem?'selected':''} onClick={filterCategory.bind(this,categoryItem)}>{categoryItem}</p>))}
                  {category !=='' && <div className="clear" onClick={filterCategory.bind(this,'')}>X</div>}
                </div>
              </div>

              <div className="task-lists">
                {tasksList &&
                  tasksList.map((answer, index) => (
                    <div className="tasks-box primary-color-lightblue-background" onClick={onTaskClicked.bind(this,answer)}>
                      <div className="tasks-title">
                        <h1>{answer.title}</h1>
                      </div>
                      <div className="tasks-objective">
                        <p>{answer.objective}</p>
                      </div>
                      {answer.studenttask && (
                        <img
                          className="tasks-schueler"
                          alt="Schüleraufgabe"
                          src={schueler}
                        />
                      )}
                      <div className="tasks-identifier">
                        <p>{answer.category}</p>
                        {answer.difficulty == 1 && (
                          <img id="diffLight" alt="Leicht" src={easy} />
                        )}
                        {answer.difficulty == 2 && (
                          <img id="diffMedium" alt="Mittel" src={medium} />
                        )}
                        {answer.difficulty == 3 && (
                          <img id="diffDifficult" alt="Schwierig" src={hard} />
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {showViewModeMessage && <ViewModeAlert onRegister={onRegister} onCancel={toggleViewModeMessage} />}
    </React.Fragment>
  );
};
export default TaskArchive;
