import React from 'react'
import {UnControlled as CodeMirror} from 'react-codemirror2'
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/monokai.css';
import 'codemirror/theme/elegant.css'
import 'codemirror/mode/python/python';
import 'codemirror/mode/shell/shell';
import 'codemirror/theme/duotone-light.css'


const CodeSolution = (props) => {
    const {solution} = props
    return (
        <div  style={{padding:'1rem', height:'auto', background:'#0000000a'}} >
            <span style={{marginLeft:"1rem"}}>Lösung</span>
            {solution && solution.length > 0 &&  <CodeMirror
            id='codeblock' 
            options={{
                theme: 'elegant',
                keyMap: 'sublime',
                mode: 'python',
                lineNumbers:true
                
              }}
            value={solution} 
            
            />}
                                                              
        </div>
    );
}

export default CodeSolution

