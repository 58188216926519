import React from 'react';
import { tasks } from '../../aufgabe-der-woche.json';

const TaskOfTheWeek = () => {
    return (
        <React.Fragment>
            <section className="content-tasks">
                <div className="answers">
                    {tasks &&
                        tasks.map((answer, index) =>
                            <div className="task-tittle">
                                <span className="withFrame">Aufgabe {answer.week}. {answer.date}</span>
                                <br/>
                                <span className="withFrame">{answer.title && <a href={answer.link} target="_blank">{answer.title}</a>}</span>
                            </div>
                        )
                    }
                </div>
             </section>
        </React.Fragment>
    );
}
export default TaskOfTheWeek;
