import React, { Component } from 'react';
import authService from '../services/authService';
import { Link } from 'react-router-dom';
import * as alert from '../alert.json';
import userService from '../services/userService'

class ethics extends Component {
    state = {
        subPaths:[{
            course:'ki_and_society',
            title:"KI & Gesellschaft",
            description:"Hier findest du eine Vielzahl an Artikeln zu verschiedenen Themen, in denen KI Anwendung in unserem gesellschaftlichen Leben findet.",
            subHeader:"Trainiere kritisches Denken",
            link:"/ethics/course=ki_and_society",
            image:"aiSociety.svg",
            tasksAttempted:0,
            totalTasks:30
        },
        {
            course:null,
            title:"Ethisch fragwürdig",
            description:"Hier kannst du testen, ob du ethische Probleme bei Anwendungsmöglichkeiten der KI selbst aufdecken kannst.",
            subHeader:"Detektivarbeit",
            link:"/ethics",
            image:"ethical.svg",
            tasksAttempted:0,
            totalTasks:30
        }],
        viewMode:true,
        taskAiSociety:0,
        taskEthics:0

       
    }

    componentDidMount() {
        let user = authService.getCurrentUser();

     
        if(!user){
            this.setState({
                viewMode:true
            })
        }
        else{

           let tasksDog = 0;
           let tasksRecycling = 0;
            this.state.subPaths.map(subPath => {
                if(!subPath.course) return;
                userService.getAttemptedTasksForCourse(subPath.course).then(({ data: response })=>{
                    let sum = 0;
                    if(subPath.course === 'ki_and_society'){
                        response.map((subchapter) => {
                            sum+= subchapter.num_attempted;
                        })
                        // let aiSociety = subPaths.find(path => path.course === 'ki_and_society')
                        
                        this.setState({taskAiSociety:sum})
                    }
                    else if(subPath === 'ethics'){
                        response.map((subchapter) => {
                            sum+= subchapter.num_attempted;
                        })
                        this.setState({taskEthics:sum})
                    }

                })
            })
        }

    
    }

    removeAlert = () => {
        this.setState({
            showAlert: false
        })
    }
    render() {
        const user = authService.getCurrentUser();
        let viewMode=true;
        // if (!user && viewMode) this.props.history.push('/auth')

        const{taskAiSociety,taskEthics} = this.state
        let course = this.props.match.params;
        if(user)viewMode=false;
        return (
            <React.Fragment>
                <section className="content">

                    {/* <h1 className="homepage header"></h1>
                    <h3 className="homepage sub-header"></h3> */}

                    <div className="container-fluid home-wrapper">
                        <div className="subpath-tiles">
                        
                            {this.state.subPaths.length > 0 && this.state.subPaths.map( (subPath) => (
                                <Link to={`${subPath.link}`} className="subpath-tile">
                                    <div className="tile-wrapper">
                                        <img className="image negative-margin" src={`/images/icons/subpaths/ethics/${subPath.image}`}/>
                                        <div className="row2 primary-color-lightgreen-background">
                                            <div className="tile-sub-header">{subPath.subHeader}</div>
                                            <div className="tile-header">{subPath.title}</div>
                                            <div className="tile-descritpion">{subPath.description}</div>
                                            { !viewMode && subPath.course === 'ki_and_society' && <div className="tile-status">{`${subPath.course === 'ki_and_society'?taskAiSociety:taskEthics}/${subPath.totalTasks} Aufgaben bearbeitet`}</div>}
                                        </div>
                                    </div>
                                </Link>))
                            }
                                               
                          
                        </div>
                    </div>

                   
                </section>
            </React.Fragment>
        );
    }
}

export default ethics;
