import React from 'react';
import Form from '../common/form';
import '../../datepicker.css';
import EditUser from './components/edit_user';
import userService from '../../services/userService';

class UserProfile extends Form {
    constructor(props)
    {
        super(props);
        this.state = {
            data: {},
            errors: [],
            togglePopup: false,
            showInvitations: false,
            showTeams : false,
            teamNameForAction:''
        }
    }

    componentDidMount(){
        this.getUser();
    }

    onTogglePopup = (teamName) => {
        const { togglePopup } = this.state;
        this.setState({
            togglePopup: !this.state.togglePopup,
            teamNameForAction:teamName
        })
    }
    
    onTeamLeave = () => {
        let teamName = this.state.teamNameForAction
        userService.leaveTeam(teamName).then(({ data }) => {
            this.setState({
                togglePopup: false
            })
            this.getUser()
        }).catch((error) => {
            this.setState({
                togglePopup: false
            })
            this.getUser()
        })
    }
    
    getUser() {
        if (this.props.user) {
            userService.getUser(this.props.user.id).then(({ data: response }) => {
                this.setState({
                    data: response
                });
            });
        }
    }

    render() {
        const { data, togglePopup } = this.state;
        return(
            <React.Fragment>
                <div className={`user-popup ${togglePopup ? 'active' : ''}`}>
                    <div className={`popup-box`}> 
                        <span className={`text`} >Are you sure you want to leave the team?</span>
                        <div className={`popup-buttons`}>
                            <span onClick={this.onTeamLeave}>Yes</span>
                            <span onClick={this.onTogglePopup.bind(this,'')}>No</span>
                        </div>
                    </div>  
                </div>
                <section className="content question">
                    <div className="container-fluid">
                        <div className="container-limit">
                            <div className={`user-profile`}>
                                <EditUser response={data} getUser={this.getUser} />
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default UserProfile;