import React, { Component } from 'react';
import Question from './question';
import Button from '../common/button';
import MyTooltip from '../common/my-tooltip';
import { Progress } from 'reactstrap';
import LeftMenu from '../left-menu/left-menu';
import { submitQuestionsVideo } from '../../entry-videos.json';
import { allQuestions } from '../../questions.json';
import questionService from '../../services/questionService';

class SubmitQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            course:this.props.match.params.course,
            current_chapter:"",
            current_question: {},
            selectedAnswers:[],
            questions:[],
            showMenu: false,
            hideMenu: false,
            success: null,
            correct: null,
            allowSubmit:true,
            message:"",
            color:"",
            totalPoints:0
        }
    }

    componentDidMount(){
        const { course,chapterId, id } = this.props.match.params;
        questionService.getQuestionByChapterId('ki_and_society', chapterId).then(({ data: response }) => {

            questionService.getMultipleQuestionsByType('ki_and_society').then(({ data: responsePoints }) => {
                
                let chapterPoints = responsePoints.filter(p => p.chapter_id === chapterId)
                let totalPoints=0;
                chapterPoints.map(pointItem => {
                    totalPoints += pointItem.correct_answers_count?pointItem.correct_answers_count:0
                })
                
                this.setState({
                   totalPoints
                })
            })

            let correct = null;
            let subState = { "message":"","color":"","allowSubmit":true}
            let selectedAnswers=[];
            let question_response = response.find(p => p.quiz_id === parseInt(id));
            let current_chapter = allQuestions.find(p => p.chapter === parseInt(chapterId))
            let current_questions = current_chapter.questions;
            let current_question = current_questions.find(p =>p.id ===  parseInt(id));
            if(question_response){
                // current_question.answers.forEach(answer => {
                //     answer.checked = question_response.answers.find(p => p.id === answer.id).checked;
                // });
                correct=question_response.correct;
                selectedAnswers=question_response.answers
                subState = this.handleMessage(question_response,true)
            }
            this.setState({
                questions: [].concat(current_questions),
                current_question,
                current_chapter,
                correct,
                selectedAnswers,
                message:subState.message,
                color:subState.color,
                allowSubmit:subState.allowSubmit
            })
        }).catch(err => {})
    }

    bodyOverflowClass = (show) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        if (show) document.body.classList.add('overflowHidden');
        else document.body.classList.remove('overflowHidden');
    }

    showLeftMenu = show => {
        this.bodyOverflowClass(show);
        this.setState({
            showMenu: show,
            hideMenu: !show
        })
    }

    submitQuestions = () => {
        const { chapterId } = this.props.match.params;
        const { current_question } = this.state;
        let questionSubmit = {}
        questionSubmit.id = current_question.id;
        questionSubmit.chapterId = chapterId;
        questionSubmit.type = "ki_and_society";
        questionSubmit.answers = current_question.answers;
        questionService.submitQuestions(questionSubmit).then(({ data: response }) => {

            questionService.getMultipleQuestionsByType('ki_and_society').then(({ data: responsePoints }) => {
                
                let chapterPoints = responsePoints.filter(p => p.chapter_id === chapterId)
                let totalPoints=0;
                chapterPoints.map(pointItem => {
                    totalPoints += pointItem.correct_answers_count
                })
                
                this.setState({
                   totalPoints
                })
            })
            let selectedAnswers = response.answers;
            let subState = this.handleMessage(response,false)

            this.setState({
                correct:response.correct,
                success: true,
                selectedAnswers,
                message:subState.message,
                color:subState.color,
                allowSubmit:subState.allowSubmit
            })
            
        }).catch(err => {
            this.setState({
                success: false
            })
        })
    }

    onNextButton = () => {
        const {chapterId, id } = this.props.match.params;
        const {course} = this.state
        this.props.history.push(`/ethics/course=${course}/chapter=${chapterId}/id=${parseInt(id) + 1}`)
    }

    onPreviousButton = () => {
        const { chapterId, id } = this.props.match.params;
        const {course} = this.state
        this.props.history.push(`/ethics/course=${course}/chapter=${chapterId}/id=${parseInt(id) - 1}`)
    }

    onHomeButton = () => {
        const {course} = this.state
        this.props.history.push(`/ethics/course=${course}`)
    }

    onHandleChange = (id, index) => {
        const { current_question } = this.state;
        current_question.answers[index].checked=!current_question.answers[index].checked
        this.setState({
            current_question
        })
    }

    handleMessage = (response,load) => {
        let message="";
        let color="";
        let allowSubmit = true;

        if(load && (response.correct || response.num_attempts === 3) ){
                message="";
                color="";
                allowSubmit=false;
                return { "message":message,"color":color,"allowSubmit":allowSubmit}
        }
        

        if(response.num_attempts < 3){
            if(response.correct){
                message="Super. Du hast Punkte gesammelt.";
                color="message-correct";
                allowSubmit=false;
            }
            else{
                message="Noch nicht ganz richtig. Versuche es nochmal.";
                color="message-more";
                
            }

        }
        else if (response.num_attempts === 3){
            allowSubmit = false;
            if(response.correct){
                message="Super. Du hast Punkte gesammelt.";
                color="message-correct";
            }
            else{
                if(response.correct_answers_count === 0){
                    message="Schade, diesmal hast du leider keine Punkte gesammelt.";
                    color="message-wrong";
                }
                else if(response.correct_answers_count > 0){
                    message="Du erhältst für die richtigen Antworten Punkte."
                    color="message-partial"
                }
            }
        }

        return { "message":message,"color":color,"allowSubmit":allowSubmit}
 
    }

    render() {
        const { current_question, questions, success, correct, current_chapter, selectedAnswers,allowSubmit, totalPoints } = this.state;
        const { course, chapterId, id } = this.props.match.params;
        let maxQuestions;
        if(questions)
        {
            maxQuestions = questions.length;
        }

        return (
            <React.Fragment>
                {
                    current_question &&
                    <React.Fragment>
                        <section className="content question">
                            <div className="container-fluid">
                                <div className="container-limit my-limit">
                                    <div className="progressBar">
                                        <div className="progress-table-bar">
                                            {/* <div>
                                                <Progress value={questions.indexOf(current_question)+1} max={maxQuestions}><span>{questions.indexOf(current_question)+1}/{maxQuestions}</span></Progress>
                                            </div> */}
                                            <div className="progress-div">
                                                <Progress value={questions.indexOf(current_question)+1} max={maxQuestions}></Progress>
                                                <div className="progress-span">{questions.indexOf(current_question)+1}/{maxQuestions}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="task-item-lightheader">Kategorie  {current_chapter?current_chapter.title:""}</div>
                                <div className="task-item-mainheader">{current_question.title} {current_question && current_question.link && <a href={current_question.link} className="primary-color-lightgreen-foreground ml-4" target="_blank">zum Artikel</a>}</div>

                                    <div className="question-wrapper">
                                        <Question correct={correct} 
                                                   key={current_question.id} 
                                                   question={current_question} 
                                                   onChange={this.onHandleChange} 
                                                   showLink={false} 
                                                   selectedAnswers={selectedAnswers} 
                                                   type="quiz"
                                                   showAnswers={!allowSubmit}  />
   
                                        {
                                            this.state.message && !(this.state.message === "") &&
                                            <div className={`question-info ${this.state.color}`}>
                                                <span>{this.state.message}</span>
                                            </div>
                                        }
                                        {
                                         !this.state.allowSubmit &&
                                        <div className="question-info message-answers">
                                            <span>Musterlösung - dies sind alle korrekten Antworten!</span>
                                        </div>
                                         }

                                
                                        <div className="submit-parent-wrapper">
                                            {
                                                (questions.length > 0) &&
                                                
                                                <div className="button-wrapper">
                                                    <div className="points-panel-wrapper">
                                                        <span>Punkte</span>
                                                        <div className="points-panel">{totalPoints}</div>
                                                    </div>
                                                    {questions.indexOf(current_question) !== 0 && <Button onClick={this.onPreviousButton} href="#" className="firstBtn" label="Vorherige Frage" />}
                                                    { this.state.allowSubmit &&  <Button onClick={this.submitQuestions} href="#" className="secondBtn primary-color-lightblue-foreground" label="Auswerten" />}
                                                    {questions && questions.indexOf(current_question)+1 < questions.length && <Button onClick={this.onNextButton} href="#" className="thirdBtn" label="Nächste Frage" />}
                                                    {questions && questions.indexOf(current_question)+1 === questions.length && <Button onClick={this.onHomeButton} href="#" className="thirdBtn" label="Speichern" />}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                       
                    </React.Fragment>
                }
                {!current_question &&
                    <React.Fragment>
                        <section className="content question">
                            <div className="container-fluid">
                                <div className="container-limit my-limit">
                                    <div className="multiple-choise-video">
                                        <div className="progressBar">
                                            <div className="progress-table-bar">
                                                <div>
                                                    <Progress value="0" max={maxQuestions}><span>0/{maxQuestions}</span></Progress>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="video-wrapper">
                                            <iframe title='entry-video' width="560" height="315" src={submitQuestionsVideo} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                                            </iframe>
                                        </div>
                                        <div className="button-video">
                                            <Button href="#" onClick={this.goToFirst} label="Weiter" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default SubmitQuestions;
