import React, { Component } from 'react';
import logo from '../img/logo_bwki.png';
import { Link } from 'react-router-dom';
import auth from '../services/authService';
import FindUs from '../components/entry-page/footer/find-us';
/* import Sponsors from '../components/entry-page/footer/sponsors'; */
import Footer from '../components/entry-page/footer/footer';
import Rounds from './entry-page/rounds';
import './entry-page/main.css'
import Quote from './entry-page/quote';
import Info from './entry-page/info';
import Intro from './entry-page/intro';

class EntryPage extends Component {

    render() {
        if (auth.getCurrentUser()) this.props.history.push('/home');
        return (
            <React.Fragment>
                <div className={`womix__backgroundcliptext`}>
                    <section className="content">
                        <div className="container-fluid home-wrapper margin-top-5">
                            <div className="middle-content entry">
                                <div className={`entry-left`}>
                                    <img src="../images/bw-ki.svg" alt="" />
                                </div>
                                <div className={`entry-right`}>
                                    <span className={`title`}>Lerne die <br/>Grundlagen der KI</span>
                                     <p className="entry-text">Lerne die Ursprünge der KI, entwickle dein erstes KI-Projekt und verstehe wie KI in unserer Gesellschaft ethisch wertvoll eingesetzt werden kann.</p>
                                    <Link className="button primary" to={'/auth'}>Registrieren</Link>
                                    <Link className="button secondary" to={'/home'}>Ohne Registrierung anschauen</Link>

                                    <div className="logos-wrapper">
                                        <a href="https://www.facebook.com/bwkiaustria" target="_blank"><img className="social-logo" src={`/images/icons/media/facebook.svg`}/></a>
                                        <a href="https://www.instagram.com/bwki_at/" target="_blank"><img className="social-logo" src={`/images/icons/media/instagram.svg`}/></a>
                                        <a href="https://www.youtube.com/channel/UCl628j22u6huuu6T67fpMzA" target="_blank"><img className="social-logo" src={`/images/icons/media/youtube.svg`}/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="footer">
                        <div className="womix__header-graphic">
                            <svg version="1.1" className="header_layer header_graphic-layer-1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1497.5 125">
                                <polygon className="st0" points="0,126.21 229.5,30 389.5,78 769.5,15 955.5,68 1259.5,0 1562,132.5 0,132.5 "></polygon>
                            </svg>
                            <svg version="1.1" className="header_layer header_graphic-layer-2" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1497.5 125">
                                <polygon className="st0" points="0,126.21 229.5,30 389.5,78 769.5,15 955.5,68 1259.5,0 1562,132.5 0,132.5 "></polygon>
                            </svg>
                            <svg version="1.1" className="header_layer header_graphic-layer-3" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1497.5 125">
                                <polygon className="st0" points="0,126.21 229.5,30 389.5,78 769.5,15 955.5,68 1259.5,0 1562,132.5 0,132.5 "></polygon>
                            </svg>
                        </div>
                    </section>
                    <div className={`entry-page`}>
                        <main>
                            <Intro />
                            <Info />
                            <footer className="scrollbar-padding" style={{ paddingRight: 17 }}>
                                <FindUs />
                                
                                <Footer />
                            </footer>
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default EntryPage;
