import 'rc-tabs/assets/index.css';
import React, { Component } from 'react';
import CodeContent from './code-content';
import { connect } from 'react-redux';
import { getAssignments, editAssignment, submitAssignment, goToNext, resetAssignment } from '../../actions/code-submission';
import { canSubmit } from '../../actions/can-submit';
import Button from '../common/button';
import AppContext from '../common/appContext'
import userService from '../../services/userService'
import { element } from 'prop-types';
import Loading from '../common/loading'

class CodeSubmission extends Component {
  state = {
    loading: false,
    showMenu: false,
    hideMenu: false,
    totalPoints:0
  };

  constructor(props) {
    super(props);
    const { params } = this.props.match;
    this.assignmentId = 0;
    if (params.id) this.assignmentId = params.id;
    if (params.course) this.course = params.course;
    this.rootPath = '/programming/tutorials'
  }

  componentDidMount() {
    const { getAssignments, canSubmitAssignments, assignments } = this.props;

    userService.getScoresForCourse(this.course).then(({data:responsePoints}) => {

      let pointsItems = responsePoints;
      let chapterPointsItems = pointsItems? pointsItems.filter(pointsItem => pointsItem.chapter_id.charAt(0)===this.assignmentId.charAt(0)):[];
      let totalPoints = 0
      chapterPointsItems.map(item => {
         totalPoints+= item.points;
      })

      this.setState({totalPoints})

  })

    if (!assignments) getAssignments(this.course);
    canSubmitAssignments()
  }

  componentWillReceiveProps(props) {
    if (props.nextUrl && props.match.url !== props.nextUrl) this.props.history.push(props.nextUrl);
    else {
      this.setState({
        loading: false
      })
    }
  }


  bodyOverflowClass = (show) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (show) document.body.classList.add('overflowHidden');
    else document.body.classList.remove('overflowHidden');
  }

  showLeftMenu = show => {
    this.bodyOverflowClass(show);
    this.setState({
      showMenu: show,
      hideMenu: !show
    })
  }

  onChange = (id, code) => {
    this.props.editAssignment(this.course, id, code);
  }

  goToPrevious = id => {
    this.bodyOverflowClass(false);
    const { assignments } = this.props;
    const assignment = assignments.find(p => p.id === id);
    const assignmentIndex = assignments.indexOf(assignment);
    let nextId = assignments[assignmentIndex - 1].id;
    this.goTo(nextId);
  }

  goToNext = id => {
    this.bodyOverflowClass(false);
    const { assignments } = this.props;
    const assignment = assignments.find(p => p.id === id);
    const assignmentIndex = assignments.indexOf(assignment);
    let nextId = assignments[assignmentIndex + 1].id;
    this.goTo(nextId);
  }

  goTo = id => {
    this.bodyOverflowClass(false);
    this.props.goToNext(`${this.rootPath}/code-submission/course=${this.course}/id=${id}`)
  }

  submitCode = (id, next) => {
    if (next) {
      this.bodyOverflowClass(false);
    }
    const { canSubmitAssignments, canSubmitAssignmentsProp } = this.props;
    canSubmitAssignments();
    if (!canSubmitAssignmentsProp) return false;

    const { assignments, submitAssignment } = this.props;
    const assignment = assignments.find(p => p.id === id);
    const assignmentIndex = assignments.indexOf(assignment);
    const code = assignment.previous_submissions ? assignment.code['submission'] : assignment.code;

    let nextUrl;
    let loading = 'save';
    this.setState({
      loading: loading
    })
    submitAssignment(this.course, id, code, nextUrl);
  }


  onNextButton = () => {
    let assignments = this.props.assignments;
    let curElement = assignments.find(p => p.id === this.assignmentId);
    let index = assignments.indexOf(curElement);

    if (curElement.end){
      this.props.history.push({
        pathname: `${this.rootPath}/conclusion-page/course=${this.course}/id=${curElement.id}`,
      })
    }
    else if (assignments[index + 1]) {
      // if there exists next assingment
      let element = assignments[index + 1]
      if(element.id.split('_')[0] === curElement.id.split('_')[0]){
        // if the next assingment is from the current chapter
        if (element.video) {
          this.props.history.push({
            pathname: `${this.rootPath}/chapter-video/course=${this.course}/id=${element.id}`,
          })
        }
        else if (element.intro) {
          this.props.history.push({
            pathname: `${this.rootPath}/intro/course=${this.course}/id=${element.id}`,
          })
        }
        else if (element.course_data) {
          this.props.history.push({
            pathname: `${this.rootPath}/course-content/course=${this.course}/id=${element.id}`,
          })
        }
        else if (element.questions && element.questions !== null) {
          let allQuestions = JSON.parse(element.questions);
          this.props.history.push({
            pathname: `${this.rootPath}/code-questions/course=${this.course}/id=${element.id}/questionId=${allQuestions.allQuestions[0].id}`
          })
        }
        else if (element.description && element.description !== null) {
          this.props.history.push({
            pathname: `${this.rootPath}/code-submission/course=${this.course}/id=${element.id}`
          })
        }
        else if (element.end) {
          this.props.history.push({
            pathname: `${this.rootPath}/conclusion-page/course=${this.course}/id=${element.id}`,
          })
        }else{
          this.props.history.push({
            pathname: `${this.rootPath}/course=${this.course}`
          })
        }

      }
      else{
          //navigate to course home if the crrent chapter doesnt have any assingment next
          this.props.history.push({
            pathname: `${this.rootPath}/course=${this.course}`
          })
      }
    }
    else{
      //navigate to course home if there is no next assignment
      this.props.history.push({
        pathname: `${this.rootPath}/course=${this.course}`
      })
    }
  }

  onBackButton = () => {
    let assignments = this.props.assignments;
    let element = assignments.find(p => p.id === this.assignmentId);
    let index = assignments.indexOf(element);

    if(element.questions){
      let allQuestions = JSON.parse(element.questions);
      let questionNum = allQuestions.allQuestions.length -1
      this.props.history.push({
        pathname: `${this.rootPath}/code-questions/course=${this.course}/id=${element.id}/questionId=${allQuestions.allQuestions[questionNum].id}`
      })
    }
    else if (element.course_data ) {
      this.props.history.push({
        pathname: `${this.rootPath}/course-content/course=${this.course}/id=${element.id}`,
      })
    }
    else if (element.intro ) {
      this.props.history.push({
        pathname: `${this.rootPath}/intro/course=${this.course}/id=${element.id}`,
      })
    }
    else if (element.video) {
      this.props.history.push({
        pathname: `${this.rootPath}/chapter-video/course=${this.course}/id=${element.id}`,
      })
    }
    else{
      let prevAssignment = assignments[index-1]
      if(prevAssignment.id.split('_')[0]===element.id.split('_')[0] ){
        if(prevAssignment.end){
          this.props.history.push({
            pathname: `${this.rootPath}/conclusion-page/course=${this.course}/id=${prevAssignment.id}`
          })
        }
        if (prevAssignment.description && prevAssignment.description !== null) {
          this.props.history.push({
            pathname: `${this.rootPath}/code-submission/course=${this.course}/id=${prevAssignment.id}`
          })
        }
        else if (prevAssignment.questions){
          let allQuestions = JSON.parse(prevAssignment.questions);
          let questionNum = allQuestions.allQuestions.length -1
          this.props.history.push({
            pathname: `${this.rootPath}/code-questions/course=${this.course}/id=${prevAssignment.id}/questionId=${allQuestions.allQuestions[questionNum].id}`
          })
        }
        else if (prevAssignment.course_data ) {
          this.props.history.push({
            pathname: `${this.rootPath}/course-content/course=${this.course}/id=${prevAssignment.id}`,
          })
        }
        else if (prevAssignment.intro ) {
          this.props.history.push({
            pathname: `${this.rootPath}/intro/course=${this.course}/id=${prevAssignment.id}`,
          })
        }
        else if (prevAssignment.video) {
          this.props.history.push({
            pathname: `${this.rootPath}/chapter-video/course=${this.course}/id=${prevAssignment.id}`,
          })
        }
        else{
          this.props.history.push({
            pathname: `${this.rootPath}/course=${this.course}`
          })
        }
      }
      else{
        this.props.history.push({
          pathname: `${this.rootPath}/course=${this.course}`
        })
      }

    }
  }

  resetCode = (id) => {
    const { resetAssignment } = this.props;
    this.setState({
      loading: 'reset'
    })
    resetAssignment(this.course,id);
  }

  goToFirst = () => {
    const id = this.props.assignments[0].id;
    this.props.history.push(`/code-submission/course=${this.course}/${id}`)
  }

  componentDidUpdate() {
    if (window.MathJax) {
      window.MathJax.Hub.Config({
        tex2jax: {
          inlineMath: [['$', '$'], ["\\(", "\\)"]],
          processEscapes: true
        }
      });
      window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub])
    }
  }

  render() {
    const { assignments, canSubmitAssignmentsProp } = this.props;
    const { showMenu, hideMenu, loading, totalPoints } = this.state;
    let assignment;
    let assignmentIndex = 0;
    if (assignments) {
      assignment = assignments.find(p => p.id === this.assignmentId);
      if (!assignment) {
        this.assignmentId = 0;
      }
      else {
        assignmentIndex = assignments.indexOf(assignment);
      }
    }

    let assignmentsLength = 0
    let currentTaskNum = 0
    let currentSubChapter = this.assignmentId.slice(-2)
    let currentChapter = this.assignmentId.split('_')[0]
    if (assignments) {
        assignments.forEach((item, index) => {
           let chapterId = item.id.split('_')[0] /// 02_34
           if ( chapterId === currentChapter  ) {
              let subChapterId = item.id.slice(-2)
              let currentSubchapterQuestions = 0
              if(item.questions){
                  currentSubchapterQuestions = JSON.parse(item.questions).allQuestions.length
              }

              if (item.description) {
                  assignmentsLength += 1;
                  if( subChapterId < currentSubChapter ) {
                      currentTaskNum += 1;
                  }
              }
              
              if (item.intro) {
                assignmentsLength += 1;
                if (subChapterId <= currentSubChapter) {
                  currentTaskNum += 1;
                }
              }

              if (item.course_data) {
                assignmentsLength += 1;
                if (subChapterId <= currentSubChapter) {
                  currentTaskNum += 1;
                }
              }

              if (item.end) {
                assignmentsLength += 1;
                if (subChapterId < currentSubChapter) {
                  currentTaskNum += 1;
                }
              }

              assignmentsLength += currentSubchapterQuestions
              if(subChapterId <=currentSubChapter)
                    currentTaskNum += currentSubchapterQuestions
        }})
    }

    //const assignmentsLength = assignments ? assignments.length : 0;
    return (
      <AppContext.Consumer>
      {context => {

         if (context === undefined) {

             throw new Error('AppConsumer must be used within a AppProvider')
         }
         let chapterHeader = ""
         if (this.course){
          chapterHeader = context.tutorials.find( p => p.course === this.course).chapters[(this.assignmentId.split('_')[0])-1]

         }
         return(
              <React.Fragment>
                {assignment &&
                  <React.Fragment>
                   
                    {
                      !assignment.video &&
                      <React.Fragment>
                        <section className="content submission">
                          <div className="container-fluid">
                            <div className="container-limit extra-limit">
                              <div className="code-submission-content">
                                <CodeContent chapter={chapterHeader}
                                              title={assignment.title}
                                              loading={loading} assign={assignment} 
                                              assignmentsLength={assignmentsLength}
                                              assignmentIndex={currentTaskNum + 1}
                                              canSubmit={canSubmitAssignmentsProp} 
                                              onChange={this.onChange} submitCode={this.submitCode} 
                                              resetCode={this.resetCode} />
                              </div>
                              
                              <div className="button-wrapper">
                                <div className="points-panel-wrapper">
                                    <span>Punkte</span>
                                    <div className="points-panel">{totalPoints}</div>
                                </div>
                                {currentTaskNum !== 0 && <Button disabled={loading} href='#' className='btn button firstBtn' label={loading === 'previous' ? "Loading..." : "Vorherige Frage"} onClick={this.onBackButton} />}
                                <Button onClick={() => this.submitCode(this.assignmentId, false)} href="#" className="secondBtn primary-color-lightblue-foreground" label="Auswerten" />
                                {currentTaskNum < assignmentsLength && <Button disabled={loading} href='#' className='btn button thirdBtn' label={loading === 'next' ? "Loading..." : "Nächste Frage"} onClick={this.onNextButton} />}
                              </div>
                            </div>
                          </div>
                        </section>
                      </React.Fragment>
                    }
                    
                  </React.Fragment>
                }
                {!assignment && <Loading />}
              </React.Fragment>
            );
          }}
      </AppContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assignments: state.assignments.assignments,
    loading: state.assignments.loading,
    canSubmitAssignmentsProp: state.canSubmit,
    nextUrl: state.assignments.nextUrl
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAssignments: (course) => { dispatch(getAssignments(course)) },
    editAssignment: (course, id, code) => { dispatch(editAssignment(course, id, code)) },
    submitAssignment: (course, id, code, nextUrl) => { dispatch(submitAssignment(course, id, code, nextUrl)) },
    canSubmitAssignments: () => { dispatch(canSubmit()) },
    goToNext: (nextUrl) => { dispatch(goToNext(nextUrl)) },
    resetAssignment: (course, id) => { dispatch(resetAssignment(course,id)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CodeSubmission);
