import { GET_MULTIPLE_QUESTIONS, EDIT_MULTIPLE_QUESTIONS, SUBMIT_MULTIPLE_QUESTIONS } from '../constants/questionTypes';

const initialState = {
    questions: null,
    success: null,
    questionSubmit_id: '',
    questionSubmit_type: '',
    questionSubmit_answers: '',
}

export default function questions(state = initialState, action, questionSubmit_id, questionSubmit_type, questionSubmit_answers) {
    switch (action.type) {
        case GET_MULTIPLE_QUESTIONS: {
            return Object.assign({}, { questions: action.questions, success: null, questionSubmit_id: null, questionSubmit_type: null, questionSubmit_answers: null})
        }
        case EDIT_MULTIPLE_QUESTIONS: {
            return Object.assign({}, { questions: action.questions, success: null})
        }
        case SUBMIT_MULTIPLE_QUESTIONS: {
            return Object.assign({}, { questions: action.questions, success: action.success, questionSubmit_id: null, questionSubmit_type: null, questionSubmit_answers: null })
        }
        default:
            return state;
    }
}
