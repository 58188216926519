import React from 'react';
import _ from 'lodash'
import ReactMarkdownWithHtml  from 'react-markdown/with-html'

import {UnControlled as CodeMirror} from 'react-codemirror2'
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/elegant.css'
import 'codemirror/mode/python/python';

const Question = ({correct, question, onChange, selectedAnswers, type , showAnswers,course}) => {
    const { id, title, link, iframe, img, content } = question;
    const order = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
    const renderers = {
        code: ({language, value}) => {
            return <CodeMirror
                      id='codeblock' 
                      className="intro-code-block"
                      options={{
                        theme: 'elegant',
                        keyMap: 'sublime',
                        mode: 'python',
                        lineNumbers:true,
                        readOnly:true
                        
                      }}
                      value={value}   
                      />
        },
        link:(props) => {
            return <a key={props.href} href={props.href} target="_blank" >{props.children}</a>
        }
      }
    return (
        <React.Fragment>
            
             <div className={`tittle no-padding-sides`}>
                <div className="title-iframe">
                    {iframe && <iframe title={id} width="800" height="500" frameBorder="0" scrolling="no" src={iframe} />}
                </div>
                {/* { type && type === 'tutorial' && (!course || course!=='python') && <span className={iframe ? "withFrame" : ""}>{title}</span>} */}
                {type && type === 'tutorial'  && <ReactMarkdownWithHtml className="intro question-title" renderers={renderers} skipHtml={false} children={title} allowDangerousHtml />}
                {content && <span>{content}</span>}
                {img && <img src={img} />}
            </div>
            <div className="answers">
                {question.answers &&
                    question.answers.map((answer, index) =>
                        
                        <div key={index} 
                            className={`inside-answers ${answer.checked && !showAnswers ? 'active' : ''} 
                                                       ${showAnswers?'disabled':""}
                                                       ${answer.iframe ? 'iframe-answer' : ''} 
                                                       ${ showAnswers && selectedAnswers && selectedAnswers.find(p => p.id === index+1 && p.correct)?(type && type==="tutorial"?"correct-answer-tutorial":"correct-answer-quiz"):"" }`}
                            >
                            <input value={showAnswers?false:answer.checked} type="checkbox" />
                            <div className="answer-content" onClick={onChange.bind(this, id, index)} data-question-id={id} id={answer.id}>
                                <div className={`answer-letter ${type && type==="tutorial" ? "primary-color-lightblue-background":"primary-color-lightgreen-background"}`}>
                                    <span>{order[index]}</span>
                                </div>
                                {!answer.iframe && 
                                        <ReactMarkdownWithHtml className="intro answer-text" renderers={renderers} skipHtml={false} children={answer.title} allowDangerousHtml />
                                }


                                {
                                    answer.iframe &&
                                    <iframe src={answer.iframe} title={answer.id} width="300" height="300" frameBorder="0" scrolling="no"></iframe>
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </React.Fragment>
    );
}
export default Question;
