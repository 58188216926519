import { GET_ASSIGNMENTS, EDIT_ASSIGNMENT, SUBMIT_ASSIGNMENT_CODE, GO_TO_NEXT, RESET_ASSIGNMENT_CODE} from '../constants/codeSubmisstionTypes';
import moment from 'moment'

const initialState = {
    assignments: null,
    loading: false,
    nextUrl: ''
};

export default function assignments(state = initialState, action) {
    switch (action.type) {
        case GET_ASSIGNMENTS:
            return {
                assignments: action.assignments,
                loading: false,
                nextUrl: ''
            };
        case EDIT_ASSIGNMENT: {
            const assignments = [...state.assignments]
            const assignment = assignments.find(p => p.id === action.id);
            const index = assignments.indexOf(assignment);
            if (assignments[index].previous_submissions) assignments[index].code.submission = action.code;
            else assignments[index].code = action.code;

            return {
                assignments,
                loading: false,
                nextUrl: ''
            };
        }
        case SUBMIT_ASSIGNMENT_CODE: {
            const assignments = [...state.assignments]
            const assignment = assignments.find(p => p.id === action.id);
            const index = assignments.indexOf(assignment);
            if (assignment.previous_submissions) {
                assignments[index].code.last_modified = moment().format("YYYY-MM-DD HH:mm:ss");
            }
            else {
                assignments[index].previous_submissions = true;
                const codeObj = {
                    submission: assignments[index].code,
                    last_modified: moment().format("YYYY-MM-DD HH:mm:ss")
                }
                assignments[index].code = codeObj;
            }
            assignments[index].results['results'] = action.results;

            return {
                assignments,
                loading: false,
                nextUrl: action.nextUrl
            };
        }
        case GO_TO_NEXT: {
            return {
                assignments: state.assignments,
                loading: false,
                nextUrl: action.nextUrl
            }
        }
        case RESET_ASSIGNMENT_CODE: {
            const assignments = [...state.assignments]
            const assignment = assignments.find(p => p.id === action.id);
            const index = assignments.indexOf(assignment);
            assignments[index].previous_submissions = false;
            assignments[index].code = action.code;
            assignments[index].results['results'] = {};
            return {
                assignments,
                loading: false,
                nextUrl: ''
            };
        }
        default:
            return state;
    }
}