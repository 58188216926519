import React from 'react';
import authService from '../services/authService';

const NotFound = (props) => {
    if (!authService.getCurrentUser()) {
        props.history.push('/')
    }
    return (
        <div className="not-found">
            <h1>NOT FOUND</h1>
        </div>
    );
}

export default NotFound;
