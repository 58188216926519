import React from 'react';

const CodeResponse = ({ results }) => {
    return (
        <React.Fragment>
            {results.map((result, index) =>
                <div key={index} className={'code-response-wrapper ' + (result.passed ? "success" : "error")}>

                    <p>
                        {result.passed && <i className="fa fa-check-square color-square"></i>}
                        {!result.passed && <i className="fa fa-times"></i>}
                        {result.name}
                    </p>
                    {result.hint && <p><i className="fa fa-exclamation-triangle color-triangle"></i> {result.hint}</p>}
                    {result.print && <p className='std-output'>{result.print}</p>}
                </div>
            )}
        </React.Fragment>
    );
}

export default CodeResponse;
