import React, { Component } from 'react';
import Joi from 'joi-browser';
import Input from './input';
import TextArea from './text-area';
import UploadIUnput from './upload-input';
import Captcha from './captcha';
import UploadImageInfo from './upload-image-info';
import Checkbox from './checkbox';
import TextEditor from './text-editor';

class Form extends Component {
    state = {
        data: {},
        errors: {}
    }

    validate = () => {
        const { error } = Joi.validate(this.state.data, this.schema, { abortEarly: false });
        if (!error) return null;
        const errors = {};
        error.details.map(p => {
            return errors[p.path[0]] = p.message
        });
        return errors;
    }

    validateProperty = (input, depend) => {
        const { name, value } = input;

        const obj = { [name]: value }
        var schema = {
            [name]: this.schema[name]
        }

        if (depend) {
            obj[depend] = this.state.data[depend]
            schema[depend] = this.schema[depend]
        }
        const { error } = Joi.validate(obj, schema);
        if (!error) return null;
        let errors = [];
        error.details.forEach(detail => {
            errors.push({ name: detail.path[0], value: detail.message })
        })
        return errors
    }

    handleChange = ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
        var usernameRegex = /^[a-zA-Z0-9]+$/;
        let depend = null;
        if (input.type !== 'file' && input.type !== 'editor') {
            depend = input.dataset['depend']
        }

        const errorMessage = this.validateProperty(input, depend);
        if (!errorMessage || errorMessage.filter(p => p.name === input.name).length === 0) {
            delete errors[input.name];
            if (depend) delete errors[depend]
        }
        else {
            errorMessage.forEach(p => {
                errors[p.name] = p.value;
            })
        }

        if (input.name === "username") {
            if (input.value.indexOf(" ") >= 0){
                errors[input.name] = 'Der Benutzername sollte eine Kombination aus Buchstaben und Zahlen ohne Leerzeichen enthalten';
            }
            else if (!input.value.match(usernameRegex)){
                errors[input.name] = 'Der Benutzername sollte eine Kombination aus Buchstaben und Zahlen ohne Leerzeichen enthalten';
            }
        }

        const data = { ...this.state.data };
        if (input.type === 'checkbox') {
            data[input.name] = !data[input.name];
        }
        else if (data.type === 'file') {
            data[input.name] = data[input.value]
        }
        else {
            data[input.name] = input.value;
        }
        this.setState({
            data,
            errors
        })
    }

    handleCaptcha = (val, name) => {
        const { data } = this.state;
        data[name] = val;
        this.setState({
            data
        })
    }

    handleSubmit = e => {
        e.preventDefault();

        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) return;

        this.submitForm();
    }

    renderSubmitButton(label, loading = false, className = '') {
        let labelName = label;
        if (loading) {
            labelName = "loading..."
        }
        return (
            <button disabled={loading ? loading : this.validate()} type='submit' className={`btn button mt-4 myButton ${className}`}>{labelName}</button>
        )
    }

    renderInput(name, label, id, value, onChange, error, horizontal, disabled, type = 'text', depend = null, tooltip = false, message = "") {
        return (
            <Input name={name} label={label} tooltip={tooltip} depend={depend} message={message} id={id} value={value} onChange={onChange} disabled={disabled} error={error} horizontal={horizontal} type={type} />
        )
    }


    renderTextArea(name, label, id, value, onChange, error, horizontal, disabled, rows, tooltip = false, message = "") {
        return (
            <TextArea name={name} label={label} tooltip={tooltip} message={message} id={id} value={value} onChange={onChange} disabled={disabled} error={error} horizontal={horizontal} rows={rows} />
        )
    }

    renderUploadInput(name, label, value, onChange, error, horizontal, maxFiles, tooltip = false, message = "") {
        return (
            <UploadIUnput name={name} label={label} tooltip={tooltip} message={message} onChange={onChange} value={value} error={error} horizontal={horizontal} maxFiles={maxFiles} />
        )
    }

    renderUploadImageInfo(label, id, horizontal, tooltip = false, message = "") {
        return (
            <UploadImageInfo id={id} tooltip={tooltip} message={message} label={label} horizontal={horizontal} />
        )
    }

    renderRecaptcha(name, label, onChange, horizontal, tooltip = false, message = "") {
        return (
            <Captcha label={label} tooltip={tooltip} message={message} horizontal={horizontal} onChange={onChange} name={name} />
        )
    }

    renderCheckbox(name, label, id, value, onChange, error, horizontal, disabled, depend, tooltip = false, message = "") {
        return (
            <Checkbox name={name} tooltip={tooltip} message={message} label={label} depend={depend} id={id} value={value} onChange={onChange} disabled={disabled} error={error} horizontal={horizontal} />
        )
    }

    renderTextEditor(name, label, value, onChange, error, horizontal, tooltip = false, message = "") {
        return (
            <TextEditor name={name} label={label} value={value} onChange={onChange} error={error} horizontal={horizontal} tooltip={tooltip} message={message} />
        )
    }
}

export default Form;
