import http from './httpService';

const {
    REACT_APP_WEB_API_URL: web_api_url,
} = process.env;

export async function getCertificate() {
    return await http.get(`${web_api_url}/certificate`);
}

export default {
    getCertificate
}