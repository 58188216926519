import React, { Component } from 'react';
import auth from '../../services/authService';
import { Redirect } from 'react-router-dom';
import Login from './login';
import Register from './register';
import ForgotPassword from './forgot-password';
import * as alert from '../../alert.json';

class Auth extends Component {
    state = {
        activeTab: 'login',
        registerTeam: false,
        olderWarning: false,
        youngerWarning: false,
        showAlert: true,
        showPanel:true,
        modal: null
    }

    showPanel = (event) => {
        event.preventDefault();
        this.setState({
            showPanel:true,
            modal: {
                title: 'KI Schule des Jahres',
                content: 'Die Schule mit den meisten aktiv teilnehmenden Schüler*innen am KI-Kurs, während der Wettbewerbszeit (März-Oktober), hat die Chance den Titel der KI-Schule des Jahres zu gewinnen. Du willst mitmachen? Markiere bei der Registrierung oder im Anschluss in deinem Benutzerprofil, dass du teilnehmen möchtest und informiere uns an welcher Schule du Schüler*in bist.',
            }
        });
    }

    closeDefinition = () =>{
        this.setState({
            showPanel:false,
            modal:null
        })
    }

    componentDidMount() {
        if (alert.auth.enabled) {
            this.setState({
                showAlert: true
            })
        }
    }

    changeTabs = (tab) => {
        this.setState({
            activeTab: tab
        })
    }

    changeIsTeam = value => {
        this.setState({
            registerTeam: value
        })
    }

    onShowOlderWarning = show => {
        this.setState({
            olderWarning: show
        })
    }

    onShowYoungerWarning = show => {
        this.setState({
            youngerWarning: show
        })
    }

    removeAlert = () => {
        this.setState({
            showAlert: false
        })
    }

    render() {
        if (auth.getCurrentUser()) return <Redirect to='/home' />

        const { activeTab, registerTeam, olderWarning, youngerWarning, showAlert, showPanel, modal  } = this.state;
        return (
            <div className="page">
                {
                    alert.auth.enabled &&
                    <div className={`myAlert auth ${showAlert ? 'show' : ''}`}>
                        <small dangerouslySetInnerHTML={{ __html: alert.auth.message }} />
                        <i onClick={this.removeAlert} className="fa fa-times"></i>
                    </div>
                }
                <div className={`login ${activeTab === 'register' ? (registerTeam ? 'activeTeam' : 'active') : ''} ${activeTab === 'register' && olderWarning ? 'warning-active' : '' && youngerWarning ? 'warning-active' : ''}`}>
                    <div className="nav-login">
                        <a onClick={() => this.changeTabs('login')} className={`${activeTab === 'login' ? 'active' : ''} user-login`}>
                            <span>Anmelden</span>
                        </a>
                        <a onClick={() => this.changeTabs('register')} className={`${activeTab === 'register' ? 'active' : ''} team-login`}>
                            <span>Registrieren</span>
                        </a>
                    </div>
                    <div className="content-login">
                        <Login changeTabs={this.changeTabs} activeTab={activeTab} />
                        <Register changeIsTeam={this.changeIsTeam} activeTab={activeTab} olderWarning={olderWarning} youngerWarning={youngerWarning} onShowOlderWarning={this.onShowOlderWarning} onShowYoungerWarning={this.onShowYoungerWarning} showPanel={this.showPanel} />
                        <ForgotPassword activeTab={activeTab} />
                    </div>
                </div>
                {
                showPanel && modal &&
                <div className="overlay-panel-container">
                    <div className="overlay-panel">
                        <div className="definition-header">{modal.title}</div>
                                <div className="section">
                                    <p className='text-justify'>{modal.content}</p>
                                </div>
                    <div className="close" onClick={this.closeDefinition}></div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default Auth;
