import React,  { Component, useState, useEffect }  from 'react';
import { Link } from 'react-router-dom';

function Cookies() {
    const [showCookies, setShowCookies] = useState(false);

    const acceptCookies = () => {
        setShowCookies(false);
        window.localStorage.setItem('bwki-accepted-cookie', true);
    }

    useEffect(() => {
        if (!window.localStorage.getItem('bwki-accepted-cookie')) setShowCookies(true);
    }, []);

    return (
        showCookies &&
        <div className="cookiebanner">
            <span>Wir benutzen Cookies.
                <Link href='/datenschutz'>
                    <a target="_blank">Mehr erfahren</a>
                </Link>
            </span>
            <div onClick={acceptCookies} className="cookiebanner-close">Einverstanden</div>
        </div>
    )
}

export default Cookies;