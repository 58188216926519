import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = ({ onChange, label = "Captcha", horizontal = true, name }) => {
    var divClass = '';
    var parentDiv = "form-group";
    var labelClasses = '';
    if (horizontal) {
        divClass = 'col-sm-9';
        labelClasses += 'col-sm-3 col-form-label'
        parentDiv += ' row';
    }

    return (
        <div className={parentDiv}>
            <label className={labelClasses}>{label}</label>
            <div className={divClass}>
                <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onChange={e => onChange(e, name)} />
            </div>
        </div>);
}

export default Captcha;