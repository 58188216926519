import React, { Component } from 'react';
import Button from '../common/button';
import assignmentsService from '../../services/assignmentsService';
import { Progress } from 'reactstrap';
import ReactMarkdownWithHtml from 'react-markdown/with-html'
import AppContext from '../common/appContext'
import CodeEditor from '../course/CodeEditor'
import codeService  from '../../services/codeService'
// Makes use of : https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/master/AVAILABLE_LANGUAGES_HLJS.MD
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015,atelierDuneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Loading from '../common/loading'

class IntroPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            course:this.props.match.params.course,
            assignment: {},
            chapters: [],
            courseContent:[],
            showLoading:true,
            currentTaskNum:0,
            maxQuestions:0,
        }
        this.rootPath =  "/programming/pythonTutorials"
    }

    componentDidMount() {
        const { id,course} = this.state;

        assignmentsService.getAssignments(course).then(({ data: response }) => {
          let assignment = response.assignments.find(p => p.id === id);
          let courseContent = assignment.course_data
          let index = 0;
          courseContent = courseContent.map((courseData) => {
            if (courseData.file_type !== "md") {
              if (courseData.file_type === "py") {
                if (courseData.file_id.includes("SOLUTION")) {
                  courseData.isSolution = true;
                } else {
                  courseData.pythonIndex = index;
                  index++;
                  // build solution filename and check if exists
                  let solution = null;
                  if (courseData.file_id.includes("EXERCISE")) {
                    let solutionFileName = courseData.file_id.replace("EXERCISE","SOLUTION");
                    let solutionList = courseContent.filter(
                      (courseItem) => courseItem.file_id === solutionFileName
                    );
                    solution = solutionList[0] ? solutionList[0].content : null;
                  }
                  courseData.solution = solution;
                  courseData.isSolution = false;
                }
              }
              return courseData;
            }
            let contentMarkdown = courseData.content;
            let newMarkDown = contentMarkdown.replace(/URL_FOR_IMAGES\//g, "/images/" + course + "/");
            courseData.content = newMarkDown;

            return courseData;
          });

          courseContent = courseContent.filter( course => !(course.file_type === 'py' && course.isSolution))
          // If there exists atleast one coding block, init pyodide
          if (index > 0){
            window.languagePluginLoader.then(this.setup_pyodide)
          }
          // Initialize Progress Bar
          let { chapterQuestionsNum, currentTaskNum } = this.initProgressBar(response);
          
          this.setState({
            assignment,
            chapters: [].concat(response.assignments),
            courseContent,
            showLoading:false,
            maxQuestions: chapterQuestionsNum,
            currentTaskNum: currentTaskNum
          })
        }).catch(err => {
          // TODO: Handle Error
        })
      }
      
      initProgressBar(response) {
        let chapterQuestionsNum = 0;
        let currentTaskNum = 0;
        let currentSubChapter = this.state.id.slice(-2);
        let currentChapter = this.state.id.split('_')[0];
  
        response.assignments.forEach((item, index) => {
          let chapterId = item.id.split('_')[0];
          if (chapterId === currentChapter) {
            let subChapterId = item.id.slice(-2);
            let currentSubchapterQuestions = 0;
            if (item.questions) {
              currentSubchapterQuestions = JSON.parse(item.questions)
                .allQuestions.length;
            }
  
            if (item.intro) {
              chapterQuestionsNum += 1;
              if (subChapterId <= currentSubChapter) {
                currentTaskNum += 1;
              }
            }
  
            if (item.course_data) {
              chapterQuestionsNum += 1;
              if (subChapterId < currentSubChapter) {
                currentTaskNum += 1;
              }
            }
  
            if (item.description) {
              chapterQuestionsNum += 1;
              if (subChapterId < currentSubChapter) {
                currentTaskNum += 1;
              }
            }
  
            if (item.end) {
              chapterQuestionsNum += 1;
              if (subChapterId < currentSubChapter) {
                currentTaskNum += 1;
              }
            }
  
            chapterQuestionsNum += currentSubchapterQuestions;
            if (subChapterId < currentSubChapter)
              currentTaskNum += currentSubchapterQuestions;
  
            if (subChapterId === currentSubChapter) {
              currentTaskNum = currentTaskNum;
            }
          }
        });
        return { chapterQuestionsNum, currentTaskNum };
      }

      onNextFromCourseContent = () => {
        const { chapters, assignment, course } = this.state;
        
        if(assignment.questions && assignment.questions !== null)
        {
            let allQuestions = JSON.parse(assignment.questions);
            this.props.history.push({
                pathname: `${this.rootPath}/code-questions/course=${course}/id=${assignment.id}/questionId=${allQuestions.allQuestions[0].id}`
            })
        }
        else if(assignment.description && assignment.description !== null)
        {
            this.props.history.push({
                pathname: `${this.rootPath}/code-submission/course=${course}/id=${assignment.id}`
            })
        }
        else if(assignment.end && assignment.end !== null)
        {
            this.props.history.push({
                pathname: `${this.rootPath}/conclusion-page/course=${course}/id=${assignment.id}`
            })
        }
        else
        {
            let nextAssignment = chapters[chapters.indexOf(assignment) + 1]
            if (!nextAssignment) {
                this.props.history.push({
                      pathname: `${this.rootPath}/course=${course}`
                })
                return
            }
            if(assignment.id.split('_')[0]=== nextAssignment.id.split('_')[0]){
                if (nextAssignment.video) {
                    this.props.history.push({
                      pathname: `${this.rootPath}/chapter-video/course=${course}/id=${nextAssignment.id}`,
                    })
                  }
                else if (nextAssignment.intro ) {
                    this.props.history.push({
                      pathname: `${this.rootPath}/intro/course=${course}/id=${nextAssignment.id}`,
                    })
                  }
                else if (nextAssignment.course_data ) {
                  this.props.history.push({
                    pathname: `${this.rootPath}/course-content/course=${course}/id=${nextAssignment.id}`,
                  })
                }
                else if (nextAssignment.questions){
                    let allQuestions = JSON.parse(nextAssignment.questions);
                    this.props.history.push({
                      pathname: `${this.rootPath}/code-questions/course=${course}/id=${nextAssignment.id}/questionId=${allQuestions.allQuestions[0].id}`
                    })
                  }
                else if (nextAssignment.description && nextAssignment.description !== null) {
                    this.props.history.push({
                      pathname: `${this.rootPath}/code-submission/course=${course}/id=${nextAssignment.id}`
                    })
                  }
                else if(assignment.end && assignment.end !== null)
                {
                    this.props.history.push({
                        pathname: `${this.rootPath}/conclusion-page/course=${course}/id=${nextAssignment.id}`
                    })
                }
                else{
                    this.props.history.push({
                      pathname: `${this.rootPath}/course=${course}`
                    })
                }
            }
            else{
                this.props.history.push({
                  pathname: `${this.rootPath}/course=${course}`
                })
            }
        }
    }

    // Adding back button for the introduction
    onBackButtonFromCourseContent = () => {
      const { chapters, assignment, course } = this.state;
      let prevAssignment = chapters[chapters.indexOf(assignment)-1]
      if(assignment.intro)
      {
          this.props.history.push({
              pathname: `${this.rootPath}/intro-page/course=${course}/id=${assignment.id}`,
          })
      }
      else if (assignment.video) {
        this.props.history.push({
          pathname: `${this.rootPath}/chapter-video/course=${course}/id=${assignment.id}`,
        });
      }
      else{
        // If there doesnt exist a previous assignment, then go back to course main
        if(!prevAssignment){
          this.props.history.push({
            pathname: `${this.rootPath}/course=${course}`
          })
        }
        else{
          // If current and previous assignments are form the same chapter
          if (assignment.id.split('_')[0] === prevAssignment.id.split('_')[0]) {
            if(prevAssignment.end){
              this.props.history.push({
                pathname: `${this.rootPath}/conclusion-page/course=${course}/id=${prevAssignment.id}`,
              })
            }
            else if (prevAssignment.description && prevAssignment.description !== null) {
              this.props.history.push({
                pathname: `${this.rootPath}/code-submission/course=${course}/id=${prevAssignment.id}`,
              })
            }
            else if (prevAssignment.questions){
                let allQuestions = JSON.parse(prevAssignment.questions);
                let questionNum = allQuestions.allQuestions.length -1
                this.props.history.push({
                    pathname: `${this.rootPath}/code-questions/course=${course}/id=${prevAssignment.id}/questionId=${allQuestions.allQuestions[questionNum].id}`,
                })
            }
            else if (prevAssignment.course_data ) {
              this.props.history.push({
                pathname: `${this.rootPath}/course-content/course=${course}/id=${prevAssignment.id}`,
              })
            }
            else if (prevAssignment.intro ) {
                this.props.history.push({
                  pathname: `${this.rootPath}/intro/course=${course}/id=${prevAssignment.id}`,
                })
            }
            else if (prevAssignment.video) {
                this.props.history.push({
                  pathname: `${this.rootPath}/chapter-video/course=${course}/id=${prevAssignment.id}`,
                })
            }
            else{
                this.props.history.push({
                  pathname: `${this.rootPath}/course=${course}`,
                })
            }
          }
          else{             
            this.props.history.push({
              pathname: `${this.rootPath}/course=${course}`,
            })
          }
        }  
      }
    }

    
    setup_pyodide = () => {
      // setup pyodide environment to run code blocks as needed
      /*
      * !!!! important -  do not format this fucntion, the existing formatting needs to be preserved for the setup_code to work correctly
      */
      var setup_code = `
    import sys, io, traceback
    from js import input_fixed
    __builtins__.input = input_fixed
  
    namespace = {}  # use separate namespace to hide run_code, modules, etc.
  
    code_to_run = ""
    def run_code(code):
      """run specified code and return stdout and stderr"""
      out = io.StringIO()
      oldout = sys.stdout
      olderr = sys.stderr
      sys.stdout = sys.stderr = out
      try:
          # change next line to exec(code, {}) if you want to clear vars each time
          exec(code, {})
      except:
          traceback.print_exc()
    
      sys.stdout = oldout
      sys.stderr = olderr
      return out.getvalue()
    `
      window.pyodide.runPython(setup_code)
    }
    
    runScript = (code, setOutput, setError) =>{
      let codeToRun = code.replaceAll('"""',"'''")
      window.pyodide.loadPackage([]).then(() => {
        window.pyodide.loadPackagesFromImports(codeToRun).then(()=>{
              const outputstr = window.pyodide.runPython(`run_code(\"\"\"${codeToRun}\"\"\")`);
              setOutput(outputstr)
            }).catch(e => {
                console.log(e.message)
                setError(e)
            })
        })
      
    }

    loadSavedCode = (fileId, setStateCallBack, setError) => {
      const {course,id} =  this.state;
      codeService.getCodeforAssignmentByFileId(course, id, [fileId]).then(({data:response}) => {
        if(response && response.output && response.output.length>0){
          if(setStateCallBack)
            setStateCallBack(response.output[0].content)
        }
        else{
          setError("No saved data to load!")
        }
      }).catch(e => {
        setError(e.message)
      })
    }

    saveCode = async (fileId,content) => {
      const {course,id} = this.state
      return await codeService.saveCodeForField(course, id, fileId, content)
    }

    render() {
      const renderers = {
        code: ({language, value}) => {
          return <SyntaxHighlighter style={atelierDuneLight} language={language} children={value} />
        },
        link:(props) => {
            return <a key={props.href} href={props.href} target="_blank" >{props.children}</a>
        }
      }
        const { assignment,id ,course, chapters , showLoading, currentTaskNum, maxQuestions, courseContent} = this.state;
        return (
            <AppContext.Consumer>
                 {context => {

                    if (context === undefined) {

                        throw new Error('AppConsumer must be used within a AppProvider')
                    }
                    let chapter_header = ""
                    if (course){
                        chapter_header = context.tutorials.find( p => p.course === course).chapters[(id.split('_')[0]-1)]
                    }
                    return(
                        <React.Fragment>
                        <section className="content question">
                                { !showLoading && <div className="container-limit my-limit">
                                    <div className="intro-section">
                                        {
                                          !assignment.intro && courseContent &&
                                            <React.Fragment>
                                              <div className="progressBar">
                                                  <div className="progress-table-bar">
                                                      <div className="progress-div">
                                                          <Progress value={currentTaskNum+1} max={maxQuestions}></Progress>
                                                          <div className="progress-span">{currentTaskNum+1}/{maxQuestions}</div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="task-item-lightheader mb-3">Kapitel {id.split('_')[0]-0}: {chapter_header}</div>
                                              <div className="task-item-mainheader">
                                                {assignment ? assignment.title : ""}
                                              </div>
                                              <div className="intro-wrapper">
                                              {
                                                courseContent && courseContent.map( contentItem => {

                                                    const {content,file_id:fileId,file_type:fileType,isSolution,solution,pythonIndex} = contentItem
                                                    if(fileType === "md"){
                                                      return <div className="intro">
                                                                <ReactMarkdownWithHtml 
                                                                    className="intro" 
                                                                    renderers={renderers} 
                                                                    skipHtml={false} 
                                                                    children={content} 
                                                                    allowDangerousHtml />
                                                            </div>
                                                    }
                                                    else if (fileType === 'py' && !isSolution){
                                                      return (<CodeEditor
                                                                  course= {course}
                                                                  id={id}
                                                                  courseItem = {contentItem}
                                                                  runCode = {this.runScript}
                                                                  saveCode = {this.saveCode}
                                                                  loadSavedCode = {this.loadSavedCode}/>)
                                                    }    
                                                })
                                              }
                                              </div>
                                              <div className="button-wrapper">
                                                  <div className="button-video">
                                                      {chapters[chapters.indexOf(assignment)-1] && 
                                                          <Button href="#" onClick={this.onBackButtonFromCourseContent} label="Zurück" />}
                                                          <Button href="#" onClick={this.onNextFromCourseContent} label="Weiter" />
                                                  </div>
                                              </div>
                                              
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>}
                                {showLoading && <Loading/>}
                        </section>
                    </React.Fragment>
                    )
                }}
            </AppContext.Consumer>
        );
    }
}

export default IntroPage;