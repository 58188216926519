import http from './httpService';

const {
    REACT_APP_WEB_API_URL: web_api_url,
} = process.env;

export async function getMyStatus() {
    return await http.get(`${web_api_url}/my-status`);
}

export default {
    getMyStatus
}