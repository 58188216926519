import React, { Component } from 'react';
import logo from '../img/logo_bwki.png';
import { Link } from 'react-router-dom';

class NavBar extends Component {
  render() {
    const { role } = this.props;
    return (
      <React.Fragment>
        <section className="header">
          <div className="container-fluid">
            <div className={`header-wrapper ${role ? 'entry' : ''}`}>
              {role &&
                <div className="nav-logo">
                  <Link to='/home'><img alt='logo' src={logo} /></Link>
                  <Link to='/home'><h1> <span>Kurs</span> <br /> Künstliche Intelligenz </h1></Link>
                </div>
              }
              {!role &&
                <div className="header-nav-wrapper">
                  <div className="nav-logo">
                    <Link to='/home'><img alt='logo' src={logo} /></Link>
                    <Link to='/home'><h1> <span>Kurs</span> <br /> Künstliche Intelligenz </h1></Link>
                  </div>
                  <div className="header-wrapper-center">
                    <Link to='/home'>Aufgaben<div className="div-helper"></div></Link>
                    <Link to='/leaderboard'>Bestenliste<div className="div-helper"></div></Link>
                    <Link to='/user-profile'>Benutzerprofil<div className="div-helper"></div></Link>
                   <Link to='/tips-and-tricks'>Tipps & Tricks<div className="div-helper"></div></Link>
                    <Link to='/my-status'>Meine Resultate<div className="div-helper"></div></Link>
                    <Link to='/certificate'>Zertifikat<div className="div-helper"></div></Link>
                    <Link to='/logout'>Abmelden<div className="div-helper"></div></Link>
                  </div>
                </div>
              }
            </div>
          </div>
        </section>
        <div className="nav-helper"></div>
      </React.Fragment>
    );
  }
}

export default NavBar;
