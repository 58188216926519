import http from './httpService'

const {
    REACT_APP_WEB_API_URL: web_api_url,
} = process.env;

export async function submitQuestions(get_answers) {
    let json = {};
    json.id = get_answers.id;
    json.chapterId = get_answers.chapterId + "";
    json.type = get_answers.type;
    json.answers = [].concat(get_answers.answers);
    const answersAsJson = JSON.stringify(json);
    return await http.post(`${web_api_url}/quiz/multiple-questions`, { question: answersAsJson });
}

export async function getMultipleQuestions() {
    return await http.get(`${web_api_url}/quiz/multiple-questions`);
}

export async function getMultipleQuestionsByType(type) {
    return await http.get(`${web_api_url}/quiz/multiple-questions/${type}`);
}

export async function getQuestionByChapterId(type, chapterId) {
    return await http.get(`${web_api_url}/quiz/multiple-questions/${type}/${chapterId}`);
}

export default {
    getMultipleQuestions,
    submitQuestions,
    getQuestionByChapterId,
    getMultipleQuestionsByType
}